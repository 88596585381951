import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MyComponent } from '../../components/my.component';

import { ApiService } from '../../services/api.service';
import { PageService } from '../../services/page.service';

@Component({
  selector: 'app-venues',
  templateUrl: './venues.component.html',
  styleUrls: ['./venues.component.scss']
})
export class VenuesComponent extends MyComponent {
    assets: string;
	venues: any = [];
	inPage: number = 12;

	constructor( private apiService: ApiService, public t: PageService, private route: ActivatedRoute ) {
        super();
        this.assets = this.t.mediaUrl;
		this.t.setTitle( 'I locali Localero' );
    }

    ngOnInit() {
		this.route.params.subscribe(params => {
		});
		// In a real app: dispatch action to load the details here.
		this.getVenues();
	}

	getVenues(letter?: string): void {
		this.apiService.getVenues(letter)
		.subscribe(data => {
			if (data.status == 200) {
				this.venues = data.data;
				this.loaded();
			} else {
				this.venues = null;
			}
		});
	}

	more(): void {
        this.inPage += 12;
    }

}
