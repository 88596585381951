import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { SearchResult } from '../components/search/items'
import { EventResult } from '../pages/event/items'
import { AuthService } from './auth.service'

import { DatePipe } from '@angular/common';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class ApiService {

    private apiUrl = 'https://api.localero.it/0.0.3';  // URL to web api
    //private ytKey = 'AIzaSyDis5cDHZ2e-FZhm-2BfyHjIMWx356CN5s';  // YT api key
    private ytKey = 'AIzaSyCaDRujD1_undwfWrUTvlBiETkLKjU3RaA';  // YT api key

    constructor(private http: HttpClient, private Auth: AuthService) { }

    login( data: any ): Observable<ApiReply> {
        

        let params = {
            data: data,
            auth: this.Auth.getToken()
        }

        const url = this.apiUrl + '/login';
        return this.http.post<ApiReply>( url, params )
        .pipe(
            tap( resp => this.log(`fetched`) ),
            catchError( this.handleError<ApiReply>(`getHome`) )
        );
        
    }

    recovery( data: any ): Observable<ApiReply> {
        

        let params = {
            data: data,
            auth: this.Auth.getToken()
        }

        

        const url = this.apiUrl + '/recovery';
        return this.http.post<ApiReply>( url, params )
            .pipe(
                tap( resp => this.log(`fetched`) ),
                catchError( this.handleError<ApiReply>(`getHome`) )
            );
    }

    registration( data: any ): Observable<ApiReply> {

        let params = {
            data: data,
            auth: this.Auth.getToken()
        }

        const url = this.apiUrl + '/registration';
        return this.http.post<ApiReply>( url, params )
            .pipe(
                tap( resp => this.log(`fetched`) ),
                catchError( this.handleError<ApiReply>(`getHome`) )
            );
    }

    userGet(): Observable<ApiReply> {
        
        let params = {
            data: {},
            auth: this.Auth.getToken()
        }    

        const url = this.apiUrl + '/user';
        return this.http.post<ApiReply>( url, params )
        .pipe(
            tap( resp => this.log(`fetched`) ),
            catchError( this.handleError<ApiReply>(`getHome`) )
        );
    }

    /** GET info from the server */
    getHome(): Observable<ApiReply> {
        const url = this.apiUrl + '/index';
        return this.http.get<ApiReply>( url )
            .pipe(
                tap(heroes => this.log(`fetched`)),
                catchError(this.handleError<ApiReply>(`getHome`))
            );
    }

    /** GET hero by id. Will 404 if id not found */
    getArtist(id: number): Observable<ApiReply> {
        const url = this.apiUrl + '/artist/' +id;
        
        return this.http.get<ApiReply>(url).pipe(
            tap(_ => this.log(`fetched artist id=${id}`)),
            catchError(this.handleError<ApiReply>(`getArtist id=${id}`))
        );
    }
    
    // idealmente  si dovrebbe aggiungere un ulteriore controllo: se il rank è impostato, allora letter parte cosi: '&letter', altrimenti parte così: '?letter'.
    getArtists(byVote: boolean = false, letter?: string): Observable<ApiReply> {
        const url = this.apiUrl + '/artists' + 
        (byVote ? '?byVote=' + byVote : '') + 
        (letter ? '?letter=' + letter : '');
        return this.http.get<ApiReply>(url).pipe(
            tap(_ => this.log(`fetched artists`)),
            catchError(this.handleError<ApiReply>(`getArtists`))
        );
    }

    // https://api.localero.it/0.0.1/media/get/2/ALL
    getArtistMedia(id: number): Observable<ApiReply> {
        const url = this.apiUrl + '/media/get/' +id+ '/ALL';
        return this.http.get<ApiReply>(url).pipe(
            tap(_ => this.log(`fetched artists`)),
            catchError(this.handleError<ApiReply>(`getArtists`))
        );
    }

    getVenue(id: number): Observable<ApiReply> {
        const url = this.apiUrl + '/venue/' +id;
        return this.http.get<ApiReply>(url).pipe(
            tap(_ => this.log(`fetched artist id=${id}`)),
            catchError(this.handleError<ApiReply>(`getArtist id=${id}`))
        );
    }

    getVenues(letter?: string): Observable<ApiReply> {
        const url = this.apiUrl + '/venues' + 
        (letter ? '?letter=' + letter : '');
        return this.http.get<ApiReply>(url).pipe(
            tap(_ => this.log(`fetched venues`)),
            catchError(this.handleError<ApiReply>(`getVenues`))
        );
    }

    getYt( yt ): Observable<any> {
        const url = 'https://www.googleapis.com/youtube/v3/videos?part=snippet&id=' + yt + '&key=' + this.ytKey;

        this.http.get(url).subscribe(response=>{  
        });

        return this.http.get<any>(url).pipe(
            tap(_ => this.log(`fetched venues`)),
            catchError(this.handleError<any>(`getVenues`))
        );
    } 


    
    // FRIENDS
        friendsSave( data: any ): Observable<ApiReply> {
           
            let params = {
                data: data,
                auth: this.Auth.getToken()
            }
            
            const url = this.apiUrl + '/invite/save';
            return this.http.post<ApiReply>( url, params )
                .pipe(
                    tap( resp => this.log(`fetched`) ),
                    catchError( this.handleError<ApiReply>(`getHome`) )
                );/* */
        }

    // FRIENDS
        artistVoteSave( data: any ): Observable<ApiReply> {
            
            let params = {
                data: data,
                auth: this.Auth.getToken()
            }
                       
            const url = this.apiUrl + '/artist/vote';
            return this.http.post<ApiReply>( url, params )
            .pipe(
                tap( resp => this.log(`fetched`) ),
                catchError( this.handleError<ApiReply>(`getHome`) )
            );/* */
        }

        artistVotes( data: any ): Observable<ApiReply> {
                let params = {
                data: data,
                auth: this.Auth.getToken()
            }
            
            const url = this.apiUrl + '/artist/votes';
            return this.http.post<ApiReply>( url, params )
            .pipe(
                tap( resp => this.log(`fetched`) ),
                catchError( this.handleError<ApiReply>(`getHome`) )
            );/* */
        }


    // VOTE

    /* Al click su "vota" verranno inviati al server i seguenti dati:
        - id evento, 
        - id artista, 
        - id user, 
        - voto
    Se verranno inseriti nel DB, ci verrà restituito un messaggio di successo, altrimenti tornerà un errore.
        */
    voteArtistEvent(event_id: number, artist_id: number, vote: number, geolocation: any) {
        const body = {
            data: {
                event: event_id,
                artist: artist_id,
                vote: vote,
                geolocation: geolocation
            },
            auth: this.Auth.getToken()
        };

        return this.http.post<ApiReply>(this.apiUrl + '/event/vote-artist', body)
        .pipe(
            tap( resp => this.log(`fetched`) ),
            catchError( this.handleError<ApiReply>(`getHome`) )
        );
    }
    


    // EVENTS
        eventSave( data: any, image: File ): Observable<ApiReply> {
            

            let auth = this.Auth.getToken()

            let formData = new FormData();
            formData.append( 'data', JSON.stringify(data) );
            formData.append( 'preview', image );
            formData.append( 'email', auth.email );
            formData.append( 'token', auth.token );

            

            const url = this.apiUrl + '/event/add';
            return this.http.post<ApiReply>( url, formData )
            .pipe(
                tap( resp => this.log(`fetched`) ),
                catchError( this.handleError<ApiReply>(`getHome`) )
            );/* */
        }
        
        getEvent(id: number): Observable<EventResult> {
            const url = this.apiUrl + '/event/' +id;
            return this.http.get<EventResult>(url).pipe(
                tap(_ => this.log(`fetched event id=${id}`)),
                catchError(this.handleError<EventResult>(`getEvent id=${id}`))
            );
        }

        getEvents(): Observable<ApiReply> {
            const url = this.apiUrl + '/events';
            return this.http.get<ApiReply>(url).pipe(
                tap(_ => this.log(`fetched events`)),
                catchError(this.handleError<ApiReply>(`getEvents`))
            );
        }

        getArtistEvents(id: number): Observable<EventResult> {
            const url = this.apiUrl + '/events/' +id;
            return this.http.get<EventResult>(url).pipe(
                tap(_ => this.log(`fetched event id=${id}`)),
                catchError(this.handleError<EventResult>(`getEvent id=${id}`))
            );
        }

        getArtistEventsPrev(id: number): Observable<EventResult> {
            const url = this.apiUrl + '/events/' +id+'/prev/';
            return this.http.get<EventResult>(url).pipe(
                tap(_ => this.log(`fetched event id=${id}`)),
                catchError(this.handleError<EventResult>(`getEvent id=${id}`))
            );
        }

    //- ABOUT ---

        preferenceLoad( ): Observable<ApiReply> {
            

            let params = {
                auth: this.Auth.getToken()
            }

            const url = this.apiUrl + '/about';
            return this.http.post<ApiReply>( url, params )
                .pipe(
                    tap( resp => this.log(`fetched`) ),
                    catchError( this.handleError<ApiReply>(`getHome`) )
                );/* */
        }

        preferenceSave( data: any ): Observable<ApiReply> {
            

            let params = {
                data: data,
                auth: this.Auth.getToken()
            }

            

            const url = this.apiUrl + '/about/save';
            return this.http.post<ApiReply>( url, params )
                .pipe(
                    tap( resp => this.log(`fetched`) ),
                    catchError( this.handleError<ApiReply>(`getHome`) )
                );/* */
        }

        accountSave( data: any ): Observable<ApiReply> {
            

            let params = {
                data: data,
                auth: this.Auth.getToken()
            }

            

            const url = this.apiUrl + '/account/save';
            return this.http.post<ApiReply>( url, params )
                .pipe(
                    tap( resp => this.log(`fetched`) ),
                    catchError( this.handleError<ApiReply>(`getHome`) )
                );/* */
        }

        preferenceImg( image: File, filename ): Observable<ApiReply> {
            

            let auth = this.Auth.getToken()

            let formData = new FormData();
            formData.append(filename, image);
            formData.append('email', auth.email);
            formData.append('token', auth.token);

            let url = '';
            switch (filename) {
                case 'photo':
                    url = this.apiUrl + '/'+filename+'/add';
                    break;
                default:
                    url = this.apiUrl + '/upload/'+filename;
            }
            
            return this.http.post<ApiReply>( url, formData )
                .pipe(
                    tap( resp => this.log(`fetched`) ),
                    catchError( this.handleError<ApiReply>(`getHome`) )
                );/* */
        }

        addVideo( ytId ): Observable<ApiReply> {
            

            let params = {
                data: {
                    "media": ytId,
                    "type": "VIDEO"
                },
                auth: this.Auth.getToken()
            }

            let url = this.apiUrl + '/VIDEO/add';
            return this.http.post<ApiReply>( url, params )
                .pipe(
                    tap( resp => this.log(`fetched`) ),
                    catchError( this.handleError<ApiReply>(`getHome`) )
                );/* */
        }

    /** GET hero by id. Will 404 if id not found */
        searchTerm(term: string): Observable<SearchResult> {
            if ( term.trim().length < 3 ) {
                // if not search term, return empty hero array.
                let data:SearchResult = {
                    "status": 100,
                    "data": {
                        events: [],
                        artists: [],
                        locals: [],
                    }
                };
                return of(data);
            }
            
            const url = this.apiUrl + '/search/' +term;
            return this.http.get<SearchResult>(url).pipe(
                tap(_ => this.log(`fetched artist term=${term}`)),
                catchError(this.handleError<SearchResult>(`getArtist term=${term}`))
            );
        }

        searchVenues(term: string): Observable<ApiReply> {
            if ( term.trim().length < 3 ) {
                // if not search term, return empty hero array.
                let data = {
                    "status": 100,
                    "data": {}
                };
                return of(data);
            }

            

            let params = {
                data: term,
                auth: this.Auth.getToken()
            }

            const url = this.apiUrl + '/venue/find';
            return this.http.post<ApiReply>( url, params )
                .pipe(
                    tap( resp => this.log(`fetched`) ),
                    catchError( this.handleError<ApiReply>(`getHome`) )
                );/* */
        }

        searchArtists(term: string): Observable<ApiReply> {
            if ( term.trim().length < 3 ) {
                // if not search term, return empty hero array.
                let data = {
                    "status": 100,
                    "data": {}
                };
                return of(data);
            }

            

            let params = {
                data: term,
                auth: this.Auth.getToken()
            }

            const url = this.apiUrl + '/artist/find';
            return this.http.post<ApiReply>( url, params )
                .pipe(
                    tap( resp => this.log(`fetched`) ),
                    catchError( this.handleError<ApiReply>(`getHome`) )
                );/* */
        }

    /* GET heroes whose name contains search term */
        searchHeroes(term: string): Observable<ApiReply[]> {
            if (!term.trim()) {
                // if not search term, return empty hero array.
                return of([]);
            }
            return this.http.get<ApiReply[]>(`api/heroes/?name=${term}`).pipe(
                tap(_ => this.log(`found heroes matching "${term}"`)),
                catchError(this.handleError<ApiReply[]>('searchHeroes', []))
            );
        }

    /** GET info from the server */
        getDayEvents( day ): Observable<ApiReply> {
            let datePipe = new DatePipe('it-IT');
            
            
            let date = datePipe.transform( new Date(day), 'yyyy-MM-dd');
            
            const url = this.apiUrl + '/events/day/'+date;
            
            return this.http.get<ApiReply>( url )
                .pipe(
                    tap(_ => this.log(`getDayEvents`)),
                    catchError(this.handleError<ApiReply>(`getHome`))
                );
        }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a message with the MessageService */
    private log(message: string) {
        //this.messageService.add('Api service: ' + message);
    }
}

export class ApiReply {
    status: number;
    data: any;
}
  