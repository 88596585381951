import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { PageService } from '../../services/page.service';

@Component({
    selector: 'app-venues-wall',
    templateUrl: './venues-wall.component.html',
    styleUrls: ['./venues-wall.component.scss']
})
export class VenuesWallComponent implements OnInit {
    @Input('list') list;
    @Input('class') class: string = 'full';

    @Output() ready: EventEmitter<string> = new EventEmitter<string>();

    private mediaUrl:string = '';

    constructor(private pageService: PageService) {
        this.mediaUrl = this.pageService.mediaUrl;
    }

    ngOnInit() {
        //console.log(this.list);
    }

    isReady() {  
        this.ready.emit();
    }
}
