import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MyComponent } from '../../components/my.component';

import { ApiService } from '../../services/api.service';
import { PageService } from '../../services/page.service';
import { AuthService } from '../../services/auth.service';
import { LoadingService } from '../../services/loading.service';

declare var jQuery;

@Component({
    selector: 'app-artist',
    templateUrl: './artist.component.html',
    styleUrls: ['./artist.component.scss']
})
export class ArtistComponent extends MyComponent {
    id: number;
    assets: string;
    artist: any = {
		biography: [],
		venues: [],
	};
    videos: any = [];
    photos: any = [];
    audios: any = [];
    thumb: any = null;
	details: any = null;
	openExcerpt: boolean = false;
	logged: boolean = true;
	mine: boolean = true;
	
	votes = {
		average: 50,
		voted: 50,
		ready: false
	}

	events = {
		prev: false,
		nextItems: null,
		prevItems: null
	}


	@ViewChild('venues') venues: ElementRef;

    constructor( private apiService: ApiService, public t: PageService, private route: ActivatedRoute, private Auth: AuthService, public loadingService: LoadingService ) {
		super( );
		this.assets = this.t.mediaUrl;
		this.t.setTitle( 'loading...' );

		// subscribe to event is change event
		this.t.updateEvent.subscribe( value => {
			if (this.events.prev){
				this.getEventsPrev();
			} else {
				this.getEvents();
			}
        });
	}

    ngOnInit() {
		this.logged = this.Auth.isLogged();

		this.loading();

        this.route.params.subscribe(params => {
			this.id = +params['id'];
			this.getArtist();		
		});
	}

	vote( vote: number ) {
        this.loadingService.loading();

        this.apiService.artistVoteSave( { 'artist_id': this.id, 'vote': vote } )
            .subscribe( json => {
                console.log(json);
                if (json && json.status == 200){
                    this.loadingService.loaded();
                } else {
                    this.loadingService.error("Qualcosa non ha funzionato", "Non siamo riusciti a registrare il tuo voto, contatta l'amministratore");
                }
            }
        );
    }

	venueWallIsReady() {
		let slickVenues = jQuery(this.venues.nativeElement).find('ul');
        slickVenues.owlCarousel({
			animateOut: 'fadeOut',
    		animateIn: 'fadeIn',
            items:1,
            center: true,
            loop:true,
            margin:0,
            dots: false,
			nav: true,
			autoplay:true,
			autoplayTimeout:6000,
			autoplayHoverPause:true
		});
		
		setTimeout(
            function(){
				slickVenues.trigger('refresh.owl.carousel', [300]);
            }, 1000
        )
    }

	eventsToggle(): void {
		this.artist.events = null;

		if ( this.events.prev ){
			if ( this.events.prevItems === null ){
				this.getEventsPrev();
			} else {
				this.artist.events = [];
				this.artist.events = JSON.parse(JSON.stringify( this.events.prevItems ));
			}
		} else {
			this.artist.events = [];
			this.artist.events = JSON.parse(JSON.stringify( this.events.nextItems ));
		}
	}

	getVotes(): void {
		this.apiService.artistVotes( this.id )
			.subscribe(data => {
				if (data.status == 200) {
					this.votes.average = data.data.average;
					this.votes.voted = data.data.voted;
					this.votes.ready = true;
				}
			});
	}

	getEvents(): void {
		this.apiService.getArtistEvents( this.id )
			.subscribe(data => {
				if (data.status == 200) {
					this.events.nextItems = data.data;
					this.artist.events = JSON.parse(JSON.stringify( this.events.nextItems ));
				} else {
					this.artist.events = [];
				}
			});
	}

	getEventsPrev(): void {
		this.apiService.getArtistEventsPrev( this.id )
			.subscribe(data => {
				if (data.status == 200) {
					this.events.prevItems = data.data;
					this.artist.events = [];
					this.artist.events = JSON.parse(JSON.stringify( this.events.prevItems ));
				} else {
					this.artist.events = [];
				}
			});
	}

    getArtist(): void {
		this.apiService.getArtist( this.id )
			.subscribe(data => {
				if (data.status == 200) {
					this.artist = data.data;
					this.events.nextItems = data.data.events;

					this.t.setTitle( this.artist.name, this.artist.mood );

					this.thumb = this.t.thumb( this.artist );
					this.details = this.getDetails();

					this.mine = this.Auth.isMe( this.id );

					// console.log( 'ArtistComponent', 'events', this.artist.events);

					this.loaded();
					this.getVotes();
				} else {
					this.artist = null;
				}
			});
		
		this.apiService.getArtistMedia( this.id )
			.subscribe(data => {
				if (data.status == 200) {
					this.videos = data.data.media.VIDEO;
					this.photos = data.data.media.PHOTO;
					this.audios = data.data.media.AUDIO;
				} else {
					this.videos = [];
					this.photos = [];
					this.audios = [];
				}
			});
	}

	getDetails(): any {
		return {
			"Band": this.artist.types,
			"Genere": this.artist.music,
			"Crew": this.artist.crew,
			"Brani originali": this.artist.original,
			"Cover": this.artist.cover,
		}	
	}

}
