import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { PageService } from '../../services/page.service';
import { AuthService } from '../../services/auth.service';
import { LoadingService } from '../../services/loading.service';
import { MatFormFieldModule } from '@angular/material';

@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.scss']
})
export class FriendsComponent implements OnInit {
    private friendsForm: FormGroup;
    private status:string = "ready";
    private active:boolean = false;
    private logged: boolean = true;

    constructor(public pageService: PageService, private apiService: ApiService, fb: FormBuilder, private renderer: Renderer2, 
        private Auth: AuthService, private loadingService: LoadingService) {

        this.friendsForm = fb.group({
            name: [""],
            email: [""],
            addresses: ["", Validators.required],
            message: ["", Validators.required]
        });

    }

    ngOnInit() {

    }

    open() {
        this.logged = this.Auth.isLogged();
        
        if ( !this.logged ){
            const nameControl = this.friendsForm.get('name');
            const emailControl = this.friendsForm.get('email');
            
            nameControl.setValidators([ Validators.required ]);
            emailControl.setValidators([ Validators.email ]);
        }

        this.active = true;
        this.renderer.addClass(document.body, 'modal-open');
    }

    close() {
        this.active = false;
        this.renderer.removeClass(document.body, 'modal-open');
    }

    invite() {
        // console.log(this.friendsForm.value);

        let json = this.friendsForm.value;

        this.loadingService.loading();

        this.apiService.friendsSave(json)
            .subscribe( json => {
                console.log(json);
                if (json && json.status == 200){
                    this.close();
                    this.loadingService.loaded();
                    console.log('Invio effettuato');
                    
                } else {
                    this.loadingService.error('Qualcosa non ha funzionato', 'Non siamo riusciti a invitare i tuoi amici, contatta l\'amministratore');
                    console.log('Invio NON effettuato');
                }
            }
        );
    }

    swap() {
        // console.log('swap');
        if (this.active){
            this.close();
        } else {
            this.open();
        }
        
        return false;
    }

}
