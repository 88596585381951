import { Component, ViewChild, OnInit, Input, Output, EventEmitter } from '@angular/core';

declare var google;

@Component({
    selector: 'app-map-input',
    templateUrl: './map-input.component.html',
    styleUrls: ['./map-input.component.scss']
})
export class MapInputComponent implements OnInit {
    @Input('lng') lng:number;
    @Input('lat') lat:number;
    @Input('address') address:string;

    @Output() changed: EventEmitter<Object> = new EventEmitter();

    @ViewChild('gmap') gmapElement: any;

    map: false;
    posizione;
    icon = {
        path: "M18.5,1.7c-7.2,0-13,5.8-13,13c0,3.5,1.4,6.7,3.7,9.1l9.3,11.5l9.4-11.5c2.3-2.3,3.7-5.5,3.7-9.1C31.5,7.5,25.7,1.7,18.5,1.7z M18.5,24.9c-5.5,0-10-4.5-10-10s4.5-10,10-10c5.5,0,10,4.5,10,10S24,24.9,18.5,24.9z",
        fillColor: '#FF850F',
        fillOpacity: 1,
        anchor: {},
        strokeWeight: 0,
        scale: 1
    };

    constructor() { }

    ngOnInit() {
        this.loadMap();
    }

    loadMap() {
        console.log( 'MapInputComponent', 'loadMap' );
        this.lat = (this.lat) ? this.lat : 45.4774852;
        this.lng = (this.lng) ? this.lng : 9.1314699;

        let initialCenter = new google.maps.LatLng( this.lat, this.lng);
        this.posizione = new google.maps.Geocoder();

        let styles = [{"featureType":"all","elementType":"labels.text.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"color":"#000000"},{"lightness":13}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#144b53"},{"lightness":14},{"weight":1.4}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#08304b"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#0c4152"},{"lightness":5}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#0b434f"},{"lightness":25}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"road.arterial","elementType":"geometry.stroke","stylers":[{"color":"#0b3d51"},{"lightness":16}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#000000"}]},{"featureType":"transit","elementType":"all","stylers":[{"color":"#146474"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#021019"}]},{"featureType": "poi","stylers": [{ "visibility": "off" }]}];

        let mapOptions = {
            center: initialCenter,
            zoom: 7,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControl: false,
            scrollwheel: false,
            scaleControl: false,
            zoomControl: true,
            disableDefaultUI: true,
            styles: styles
        };

        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);
        
        this.addMarkers();
    }

    find() {
        let address = this.address;
        
        this.posizione.geocode( { 'address': address }, function( results, status ) {
            if (status == google.maps.GeocoderStatus.OK) {
                this.marker.setPosition(results[0].geometry.location);
                this.map.setCenter(results[0].geometry.location);
                var ll= this.marker.getPosition();
                this.lat = ll.lat();
                this.lng = ll.lng();
            } else {
                alert("Posizione non trovata per il seguente motivo: " + status);
            }
        });

        // to prevent "display to hidden problem"
        google.maps.event.trigger( this.map, 'resize' );
    }

    addMarkers() {
        let myLatlng = new google.maps.LatLng( this.lat, this.lng );
        this.icon.anchor = new google.maps.Point(19, 32);
        let marker = new google.maps.Marker({
            position: myLatlng,
            draggable: true,
            map: this.map,
            icon: this.icon,
            title:"local"
        });

        google.maps.event.addListener(marker, "dragend", () => {
            var ll = marker.getPosition();
            this.lat = ll.lat();
            this.lng = ll.lng();

            let data = {
                'lat': this.lat,
                'lng': this.lng,
                'address':  this.address
            }

            this.emit();
        });
    }

    emit(){
        this.changed.emit( { "lat": this.lat, "lng": this.lng, "address": this.address } );
    }

}