import { OnInit } from '@angular/core';
import { LoadingService } from '../services/loading.service';

export class MyComponent implements OnInit {
	status: string = 'loading';
	loadingService: LoadingService;

	constructor( ) {
	}

	ngOnInit() {
		this.loadingService.loading();

		this.status = 'loading';
	}
	
	loading(): void{
		this.loadingService.loading();

		this.status = 'loading';
	}

	loaded(): void{
		this.loadingService.loaded();

		this.status = 'ready';
	}

	error( title: string, message: string, keepAfterNavigationChange = false ): void{
		this.loadingService.error( title, message )
	}
}
