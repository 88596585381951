import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

import { PageService } from '../../services/page.service';

@Component({
    selector: 'app-dot-wall2',
    templateUrl: './dot-wall2.component.html',
    styleUrls: ['./dot-wall2.component.scss']
})
export class DotWall2Component implements OnInit {

  @Input('list') list;
  @Input('class') class: string = 'default';

  @Output() ready: EventEmitter<string> = new EventEmitter<string>();

  private mediaUrl:string = '';

  constructor(private pageService: PageService) { 
      this.mediaUrl = this.pageService.mediaUrl;
  }

  ngOnInit() {
  }

  isReady() {  
      this.ready.emit();
  }

  flip( artist ) {  
      artist.flipped = !artist.flipped;
  }

}
