import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'crop'
})
export class CropPipe implements PipeTransform {

    transform(value: any, end?: any, separator?: any): any {
        if (value == null || typeof value == 'undefined') {
            return '';
        }

        if (typeof end === 'undefined') {
            end = 45;
        }

        if (typeof separator === 'undefined') {
            separator = ' ';
        }

        if (value && value.length > end){
            value = value.substring(0, end);

            var i = -1;
            i = (value + '').lastIndexOf(separator);

            if (i >= 0){
                value = value.substring(0, i);
            }

            return value+' ...';
        } else {
            return value;
        }
    }

}
