let districts = [
    {'id':"74", 'code':'AG'},
    {'id':"144", 'code':'AL'},
    {'id':"223", 'code':'AN'},
    {'id':"266", 'code':'AO'},
    {'id':"373", 'code':'AP'},
    {'id':"3477", 'code':'AQ'},
    {'id':"316", 'code':'AR'},
    {'id':"387", 'code':'AT'},
    {'id':"412", 'code':'AV'},
    {'id':"533", 'code':'BA'},
    {'id':"642", 'code':'BG'},
    {'id':"691", 'code':'BI'},
    {'id':"610", 'code':'BL'},
    {'id':"632", 'code':'BN'},
    {'id':"744", 'code':'BO'},
    {'id':"923", 'code':'BR'},
    {'id':"906", 'code':'BS'},
    {'id':"540", 'code':'BT'},
    {'id':"751", 'code':'BZ'},
    {'id':"1038", 'code':'CA'},
    {'id':"1175", 'code':'CB'},
    {'id':"1529", 'code':'CE'},
    {'id':"2058", 'code':'CH'},
    {'id':"1355", 'code':'CI'},
    {'id':"1099", 'code':'CL'},
    {'id':"2486", 'code':'CN'},
    {'id':"2283", 'code':'CO'},
    {'id':"2444", 'code':'CR'},
    {'id':"2396", 'code':'CS'},
    {'id':"1831", 'code':'CT'},
    {'id':"1832", 'code':'CZ'},
    {'id':"2637", 'code':'EN'},
    {'id':"2010", 'code':'FC'},
    {'id':"2746", 'code':'FE'},
    {'id':"2821", 'code':'FG'},
    {'id':"2796", 'code':'FI'},
    {'id':"2741", 'code':'FM'},
    {'id':"2956", 'code':'FR'},
    {'id':"3086", 'code':'GE'},
    {'id':"3195", 'code':'GO'},
    {'id':"3274", 'code':'GR'},
    {'id':"3342", 'code':'IM'},
    {'id':"3372", 'code':'IS'},
    {'id':"2469", 'code':'KR'},
    {'id':"3522", 'code':'LC'},
    {'id':"3520", 'code':'LE'},
    {'id':"3612", 'code':'LI'},
    {'id':"3629", 'code':'LO'},
    {'id':"3495", 'code':'LT'},
    {'id':"3680", 'code':'LU'},
    {'id':"4470", 'code':'MB'},
    {'id':"3730", 'code':'MC'},
    {'id':"4037", 'code':'ME'},
    {'id':"4071", 'code':'MI'},
    {'id':"3824", 'code':'MN'},
    {'id':"4111", 'code':'MO'},
    {'id':"1421", 'code':'MS'},
    {'id':"3952", 'code':'MT'},
    {'id':"4575", 'code':'NA'},
    {'id':"4667", 'code':'NO'},
    {'id':"4689", 'code':'NU'},
    {'id':"4790", 'code':'OR'},
    {'id':"4720", 'code':'OT'},
    {'id':"4933", 'code':'PA'},
    {'id':"5141", 'code':'PC'},
    {'id':"4893", 'code':'PD'},
    {'id':"5096", 'code':'PE'},
    {'id':"5092", 'code':'PG'},
    {'id':"5277", 'code':'PI'},
    {'id':"5434", 'code':'PN'},
    {'id':"5524", 'code':'PO'},
    {'id':"4984", 'code':'PR'},
    {'id':"5285", 'code':'PT'},
    {'id':"5093", 'code':'PU'},
    {'id':"5021", 'code':'PV'},
    {'id':"5478", 'code':'PZ'},
    {'id':"5663", 'code':'RA'},
    {'id':"5682", 'code':'RC'},
    {'id':"5683", 'code':'RE'},
    {'id':"5639", 'code':'RG'},
    {'id':"5725", 'code':'RI'},
    {'id':"5908", 'code':'RM'},
    {'id':"5735", 'code':'RN'},
    {'id':"6000", 'code':'RO'},
    {'id':"6067", 'code':'SA'},
    {'id':"6889", 'code':'SI'},
    {'id':"6971", 'code':'SO'},
    {'id':"3425", 'code':'SP'},
    {'id':"6915", 'code':'SR'},
    {'id':"6662", 'code':'SS'},
    {'id':"6691", 'code':'SV'},
    {'id':"7152", 'code':'TA'},
    {'id':"7195", 'code':'TE'},
    {'id':"7420", 'code':'TN'},
    {'id':"7292", 'code':'TO'},
    {'id':"7385", 'code':'TP'},
    {'id':"7210", 'code':'TR'},
    {'id':"7459", 'code':'TS'},
    {'id':"7443", 'code':'TV'},
    {'id':"7512", 'code':'UD'},
    {'id':"7675", 'code':'VA'},
    {'id':"7734", 'code':'VC'},
    {'id':"7720", 'code':'VE'},
    {'id':"7804", 'code':'VI'},
    {'id':"7757", 'code':'VR'},
    {'id':"7996", 'code':'VT'},
    {'id':"7799", 'code':'VV'}
];
export { districts };