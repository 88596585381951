import { Component, OnInit, HostListener } from '@angular/core';

import { PageService } from '../../services/page.service';

import { AuthService } from '../../services/auth.service';

import { User } from '../../_models/user';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    title: string = 'Segui la musica! Artisti, eventi, locali...<br>cerca quello che ti interessa! ';
    mood: string = '';
    class: string = 'page';
    scrolled: boolean = false;
    scrolledActive: boolean = false;
    scrolledPrev: number = 0;
    subscription: any;
    currentUser: User = undefined;

    constructor( public pageService: PageService, private authenticationService: AuthService ) {
        //this.title = this.pageService.title;
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

        this.pageService.titleChange.subscribe( value => {
            this.title = value.title;
            this.mood = value.mood;
            this.class = value.class;
        });
    }

    @HostListener("window:scroll", []) 
    
    onScroll(): void {
        this.scrolled = (window.scrollY >= 150) ? true : false;
        this.scrolledActive = ( this.scrolledPrev >= window.scrollY ) ? true : false;
        this.scrolledPrev = window.scrollY;
    }

    ngOnInit() {

    }

}
