import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { PageService } from '../../services/page.service';
import { AuthService } from '../../services/auth.service';
import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'app-nav-login',
    templateUrl: './nav-login.component.html',
    styleUrls: ['./nav-login.component.scss']
})
export class NavLoginComponent implements OnInit {
    private active:boolean = false;
    private login:string = 'loading';

    private mediaUrl:string;
    private status:string = "ready";
    private loginForm: FormGroup;
    private recoveryForm: FormGroup;
    private registrationForm: FormGroup;

    constructor( public pageService: PageService, private apiService: ApiService, fb: FormBuilder, private renderer: Renderer2, 
        private Auth: AuthService, private loadingService: LoadingService ) {
        this.mediaUrl = this.pageService.mediaUrl;

        this.loginForm = fb.group({
            email: ["", Validators.email],
            password: ["", Validators.required]
        });

        this.recoveryForm = fb.group({
            email: ["", Validators.email]
        });

        this.registrationForm = fb.group({
            name: ["", Validators.required],
            email: ["", [Validators.required, Validators.email]],
            password: ["", Validators.required],
            privacy: ["", Validators.requiredTrue]
        });
    }

    ngOnInit() {
        // this.Auth.initUser();
    }

    open() {
        this.active = true;
        this.renderer.addClass(document.body, 'modal-open');
    }

    close() {
        this.active = false;
        this.renderer.removeClass(document.body, 'modal-open');
    }

    loginMe() {
        console.log('NavLoginComponent', 'loginMe', this.loginForm.value);

        let json = this.loginForm.value;

        this.loadingService.loading();

        this.loginMeCall( json );
    }

    loginMeCall( json ) {
        this.apiService.login(json)
            .subscribe( json => {
                console.log('NavLoginComponent', 'loginMeCall', json);
                if (json && json.status == 200){
                    this.Auth.setUser( json.data.user );
                    this.close();
                    this.loadingService.loaded();
                } else {
                    this.loadingService.error('Username e/o password errate', 'I dati inseriti non coincidono con quelli indicati in fase di registrazione');
                    this.status = 'error';
                    console.log('error login');
                }
            }
        );
    }
    
    recoveryMe() {
        console.log('NavLoginComponent', 'recoveryMe', this.recoveryForm.value);

        let json = this.recoveryForm.value;

        this.loadingService.loading();

        this.recoveryMeCall( json );
    }

    recoveryMeCall( json ) {
        this.apiService.recovery(json)
            .subscribe( json => {
                console.log('NavLoginComponent', 'recoveryMeCall', json);
                if (json && json.status == 200){
                    //this.Auth.setUser( json.data.user );
                    this.close();
                    this.loadingService.success('Recupera password', 'Abbiamo inviato al tuo indiizzo una e-mail con le informazione per recuperare la tua password');
                } else {
                    this.loadingService.error('Email non trovata', 'L\'indirizzo email inserito non è presente');
                    this.status = 'error';
                    console.log('error login');
                }
            }
        );
    }

    signMeUp() {
        let json = this.registrationForm.value;

        json.type = 'artist';
        json.username = json.name;

        this.loadingService.loading();

        this.apiService.registration(json)
            .subscribe( jsonResponce => {
                if (jsonResponce){
                    switch (jsonResponce.status) {
                        case 200:
                            this.loginMeCall( json );
                            break;
                        case 402:
                            this.loadingService.error('Si è verificato un errore', 'Alcuni dati inseriti risultano mancanti o non validi');
                            this.status = 'error';
                            console.log('error login');
                            break;
                        case 403:
                            this.loadingService.error('Si è verificato un errore', 'Lo username o l\'indirizzo email sono già registrati su Localero.it');
                            this.status = 'error';
                            console.log('error login');
                            break;
                        default:
                            this.loadingService.error('Si è verificato un errore', 'Riprovare piu tardi se il problema persiste contattare lo staff di Localero.it');
                            this.status = 'error';
                            break;
                    }
                } else {
                    this.loadingService.error('Si è verificato un errore', 'Riprovare piu tardi se il problema persiste contattare lo staff di Localero.it');
                    this.status = 'error';
                }
            }
        );
        
    }

    swap( login = 'login' ) {
        this.login = login;
        if ( this.login != 'recovery' ){
            if (this.active){
                this.close();
            } else {
                this.open();
            }
        }

        return false;
    }

}
