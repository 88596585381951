import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PageService } from '../../services/page.service';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-chessboard2',
  templateUrl: './chessboard2.component.html',
  styleUrls: ['./chessboard2.component.scss']
})
export class Chessboard2Component implements OnInit {
  @Output() updated: EventEmitter<any> = new EventEmitter();

  @Input()
      set list( inputList: any[] ) {
          this.ready = ( inputList === null ) ? false : true;
          this.item = inputList;
          this.empty = (this.item) ? this.item.lenght == 0 : true;
          this.getEvents();
      }
  
  private ready: boolean = false;
  private empty: boolean = false;
  item = null;

  private mediaUrl:string = '';
  private myId:number = null;

  constructor(private pageService: PageService, private Auth: AuthService) {
      this.mediaUrl = this.pageService.mediaUrl;
  }

  today = new Date(); 
  nextEvents = [];

  // funzione che itera tutti gli event e li divide per eventi passati ed eventi prossimi
  getEvents() {
      this.item.forEach(event => {
        const dateInMilliseconds = event.when;
        const formattedDate = new Date(dateInMilliseconds);
        this.nextEvents.push(event)
      }) 
  }
  
  ngOnInit() {
      this.getEvents();
  
  }       

  color = 'accent';
  checked = false;

  changed(){
      this.checked = !this.checked;
      // ogni volta che richiamo la funzione, si svuotano entrambi gli array e si riempe solo quello che ci interessa
      this.nextEvents = [];
      this.getEvents();
  }

}
