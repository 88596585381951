import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule }   from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS }    from '@angular/common/http';
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MAT_DATE_LOCALE
} from '@angular/material';

import { AppRoutingModule }     from './app-routing.module';

//- PAGES ---
import { AppComponent } from './app.component';
import { ArtistComponent } from './pages/artist/artist.component';
import { ArtistsComponent } from './pages/artists/artists.component';
import { HomeComponent } from './pages/home/home.component';
import { MobileComponent } from './pages/mobile/mobile.component';
import { EventComponent } from './pages/event/event.component';
import { EventsComponent } from './pages/events/events.component';
import { VenueComponent } from './pages/venue/venue.component';
import { VenuesComponent } from './pages/venues/venues.component';
import { AboutComponent } from './pages/about/about.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { RankingComponent } from './pages/ranking/ranking.component';

//- SERVICES ---
import { PageService } from './services/page.service';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { MessageService }       from './services/message.service';
import { LoadingService }       from './services/loading.service';

//- HELPERS ---
import { JwtInterceptor } from './_helpers/jwt.interceptor';

import { TagCloudComponent } from './_atom/tag-cloud/tag-cloud.component';
import { FileUploadComponent } from './_atom/file-upload/file-upload.component';

//- COMPONENTS ---
import { HeaderComponent } from './components/header/header.component';
import { MessagesComponent }    from './components/messages/messages.component';
import { ArtistsWallComponent } from './components/artists-wall/artists-wall.component';
import { DotWallComponent } from './components/dot-wall/dot-wall.component';
import { EventsWallComponent } from './components/events-wall/events-wall.component';
import { ChessboardComponent } from './components/chessboard/chessboard.component';
import { MapsComponent } from './components/maps/maps.component';
import { MapsComponent2 } from './components/maps2/maps2.component';
import { MapCalendarComponent } from './components/map-calendar/map-calendar.component';
import { EventsCalendarComponent } from './components/events-calendar/events-calendar.component';
import { StripeCalendarComponent } from './components/stripe-calendar/stripe-calendar.component';
import { NavHamburgerComponent } from './components/nav-hamburger/nav-hamburger.component';
import { NavLoginComponent } from './components/nav-login/nav-login.component';
import { NavBadgeComponent } from './components/nav-badge/nav-badge.component';
import { SearchComponent } from './components/search/search.component';
import { PosterComponent } from './components/poster/poster.component';
import { DetailsComponent } from './components/details/details.component';
import { CardSmallComponent } from './components/card-small/card-small.component';
import { VenuesWallComponent } from './components/venues-wall/venues-wall.component';
import { MoreComponent } from './components/more/more.component';
import { YtWallComponent } from './components/yt-wall/yt-wall.component';
import { PhotoWallComponent } from './components/photo-wall/photo-wall.component';
import { SoundcloudWallComponent } from './components/soundcloud-wall/soundcloud-wall.component';
import { LoadingComponent } from './components/loading/loading.component';
import { EventFormComponent } from './components/event-form/event-form.component';
import { MapInputComponent } from './components/map-input/map-input.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { FriendsComponent } from './components/friends/friends.component';
import { TalkComponent } from './components/talk/talk.component';

import { CropPipe } from './pipes/crop.pipe';
import { UrlencodePipe } from './pipes/urlencode.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { LimitToPipe } from './pipes/limit-to.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SafeStylePipe } from './pipes/safe-url.pipe';
import { NotEmptyPipe } from './pipes/not-empty.pipe';

//- DIRECTIVE ---
import { IsLastDirective } from './directives/is-last.directive';

//- LANGUAGES ---
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import { VoteComponent } from './components/vote/vote.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { MissionComponent } from './pages/mission/mission.component';
import { RulesComponent } from './pages/rules/rules.component';
import { FooterComponent } from './components/footer/footer.component';
import { DotWall2Component } from './components/dot-wall2/dot-wall2.component';
import { Chessboard2Component } from './components/chessboard2/chessboard2.component';
import { VoteEventComponent } from './pages/vote-event/vote-event.component';

registerLocaleData(localeIt, localeItExtra);

@NgModule({
    declarations: [
        AppComponent,
        ArtistComponent,
        ArtistsComponent,
        HomeComponent,
        MobileComponent,
        EventComponent,
        EventsComponent,
        VenueComponent,
        VenuesComponent,
        AboutComponent,
        HeaderComponent,
        MessagesComponent,
        ArtistsWallComponent,
        DotWallComponent,
        EventsWallComponent,
        ChessboardComponent,
        CropPipe,
        MapsComponent,
        EventsCalendarComponent,
        StripeCalendarComponent,
        IsLastDirective,
        MapCalendarComponent,
        UrlencodePipe,
        NavHamburgerComponent,
        NavLoginComponent,
        SearchComponent,
        PosterComponent,
        DetailsComponent,
        KeysPipe,
        CardSmallComponent,
        VenuesWallComponent,
        LimitToPipe,
        MoreComponent,
        YtWallComponent,
        SafeStylePipe,
        SafeUrlPipe,
        PhotoWallComponent,
        SoundcloudWallComponent,
        NotEmptyPipe,
        LoadingComponent,
        NavBadgeComponent,
        SettingsComponent,
        EventFormComponent,
        TagCloudComponent,
        FileUploadComponent,
        MapInputComponent,
        AutocompleteComponent,
        FriendsComponent,
        TalkComponent,
        VoteComponent,
        PrivacyComponent,
        MissionComponent,
        RankingComponent,
        FooterComponent,
        FooterComponent,
        DotWall2Component,
        MapsComponent2,
        RulesComponent,
        Chessboard2Component,
        VoteEventComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        AuthService, ApiService, PageService, MessageService, LoadingService],
    bootstrap: [AppComponent]
})
export class AppModule { }