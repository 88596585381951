import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MyComponent } from '../../components/my.component';

import { ApiService } from '../../services/api.service';
import { PageService } from '../../services/page.service';
import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss']
})
export class EventsComponent extends MyComponent {
    assets: string;
    events: any = [];
    inPage: number = 12;

    constructor( private apiService: ApiService, public t: PageService, private route: ActivatedRoute, public loadingService: LoadingService ) {
        super(  );
        this.assets = this.t.mediaUrl;
        this.t.setTitle('Gli eventi Localero in programma');
    }

    ngOnInit() {
        this.loading();
        this.getEvents();
        console.log(this.events);
        
    }

    getEvents(): void {
		this.apiService.getEvents()
			.subscribe(data => {
				if (data.status == 200) {
                    this.events = data.data;
					this.loaded();
				} else {
					this.events = [];
                }
			});
    }
    
    more(): void {
        this.inPage += 12;
    }

}
