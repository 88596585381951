import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MyComponent } from '../../components/my.component';

import { ApiService } from '../../services/api.service';
import { PageService } from '../../services/page.service';
import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'app-artists',
    templateUrl: './artists.component.html',
    styleUrls: ['./artists.component.scss']
})
export class ArtistsComponent extends MyComponent {
    assets: string;
    artists: any = [];
    inPage: number = 12;

    constructor( private apiService: ApiService, public t: PageService, private route: ActivatedRoute, public loadingService: LoadingService ) {
        super(  );
        this.assets = this.t.mediaUrl;
        this.t.setTitle('Gli artisti Localero');
    }

    ngOnInit() {
        this.loading();
        this.getArtists();
    }

    getArtists(letter?: string): void {
		this.apiService.getArtists(false, letter)
			.subscribe(data => {
				if (data.status == 200) {
                    this.artists = data.data;
					this.loaded();
				} else {
					this.artists = null;
				}
			});
	}

    more(): void {
        this.inPage += 12;
        console.log('ciaoi');
        
    }
}
