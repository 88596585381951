import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
    selector: 'app-nav-hamburger',
    templateUrl: './nav-hamburger.component.html',
    styleUrls: ['./nav-hamburger.component.scss']
})
export class NavHamburgerComponent implements OnInit {
    private active:boolean = false;

    constructor(private renderer: Renderer2) { }

    ngOnInit() {
    }

    open() {
        this.active = true;
        this.renderer.addClass(document.body, 'modal-open');
    }

    close() {
        this.active = false;
        this.renderer.removeClass(document.body, 'modal-open');
    }

    swap() {
        // console.log('swap');
        if (this.active){
            this.close();
        } else {
            this.open();
        }
    }

}
