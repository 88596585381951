export class Event {
    id: number = null;
    title: string = '';
    where: string = '';
    when: string = '';
    picture: string = '';
    locandina: string = '';
    artist: string = '';
    artist_id: string = '';
    local_id: string = '';
    types: string = '';
    genres: string = '';
    address: string = '';
    city: string = '';
}