import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'limitTo'
})
export class LimitToPipe implements PipeTransform {

    transform(items: any, limit?: any): any {
        if (typeof limit === 'undefined') {
            limit = 10;
        }

        return items.slice(0, limit)
    }

}
