import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoadingService } from '../../services/loading.service'

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: string;
    title: string;
    type: string;
    show: boolean = false;
    
    constructor(private loadingService: LoadingService) { }

    ngOnInit() {
        this.subscription = this.loadingService.getMessage().subscribe(message => {
            // console.log('LoadingComponent', 'subscribe', message);
            
            switch (message.type) {
                case 'loaded':
                    this.show = false; 
                    break;
                default:
                    this.show = true;
                    this.type = message.type; 
                    this.title = message.title; 
                    this.message = message.text;
                    break;
            }
        });
    }

    close() {
        this.show = false; 
        // console.log('LoadingComponent', 'close', this.show);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}