import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MyComponent } from '../../components/my.component';

import { ApiService } from '../../services/api.service';
import { PageService } from '../../services/page.service';
import { LoadingService } from '../../services/loading.service';

@Component({
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss']
})
export class RankingComponent extends MyComponent {
  assets: string;
  artists: any = [];
  inPage: number = 12;

  constructor( private apiService: ApiService, public t: PageService, private route: ActivatedRoute, public loadingService: LoadingService ) {
    super(  );
    this.assets = this.t.mediaUrl;
    this.t.setTitle('CLASSIFICA ARTISTI');
  }

  ngOnInit() {
    this.loading();
    this.getArtists();
  }

  getArtists(): void {
		this.apiService.getArtists(true)
			.subscribe(data => {
				if (data.status == 200) {
          // data.data.sort((a, b) => b.name.localeCompare(a.name));
          this.artists = data.data;
					this.loaded();
				} else {
					this.artists = null;
				}
			}
    );
	}

  more(): void {
    this.inPage += 12;
}


}