import { Component } from '@angular/core';

import { AuthService } from './services/auth.service';
import { ApiService } from './services/api.service';

import { User } from './_models/user';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'app';

    constructor( private authenticationService: AuthService, private apiService: ApiService ) {
        // console.log('AppComponent', 'constructor');

        this.authenticationService.currentUser.subscribe(
            x => {
                // console.log('AppComponent', 'constructor', 'subscribe', x);
                if( x ){
                    this.apiService.userGet()
                        .subscribe( json => {
                            // console.log('AppComponent', 'userGet', json);

                            if (json && json.status == 200){
                                //- ---
                            } else {
                                if( x ){
                                    this.authenticationService.clearUser();
                                }
                            }
                        }
                    );
                }
            }
        );
    }

    ngOnInit() {

	}
}
