import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tag-cloud',
  templateUrl: './tag-cloud.component.html',
  styleUrls: ['./tag-cloud.component.scss']
})
export class TagCloudComponent implements OnInit {
    @Input('tags') inputTags;

    @Output() setValues: EventEmitter<string[]> = new EventEmitter();

    private values:string[] = [''];

    constructor() { }

    ngOnInit() {
        console.log( 'TagCloudComponent' );
        // console.log( 'TagCloudComponent', this.inputTags );
    }

    change( value ) {
        console.log( 'TagCloudComponent', 'changed' );
        value.checked = (value.checked) ? 0 : 1;
        
        console.log( 'TagCloudComponent', 'changed', this.inputTags );

        this.setValues.emit( this.inputTags );
    }
}
