import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

import { ApiService } from '../../services/api.service';

@Component({
    selector: 'app-map-calendar',
    templateUrl: './map-calendar.component.html',
    styleUrls: ['./map-calendar.component.scss']
})
export class MapCalendarComponent implements OnInit {
    @Input('coords') coords;
    @Input('days') days;
    
    private markers;
    
    constructor( private apiService: ApiService ) { }

    ngOnInit() {
        this.markers = [];
    }

    ngOnChanges(obj: SimpleChanges) {
        // on days change load the first day events
        this.changeDay( this.days[0] );
    }

    changeDay( day ) {
        if (!day) {
            return false;
        }
        // console.log('MapCalendarComponent', 'changeDay', day);
        
        // load selected day events
        this.apiService.getDayEvents( day )
            .subscribe(data => {
                if (data && data.status == 200){
                    this.markers = data.data;
                } else {
                    this.markers = [];
                }
            });
    }
}
