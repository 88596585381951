import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

import { PageService } from '../../services/page.service';

declare var jQuery;

@Component({
    selector: 'app-soundcloud-wall',
    templateUrl: './soundcloud-wall.component.html',
    styleUrls: ['./soundcloud-wall.component.scss']
})
export class SoundcloudWallComponent implements OnInit {
    @Input('list') list;
    @Input('class') class: string = 'full';

    @ViewChild('audio') audio: ElementRef;

    private mediaUrl: string = '';

    constructor(private tool: PageService) {
        this.mediaUrl = this.tool.mediaUrl;
    }

    ngOnInit() {
        console.log(this.list);
    }

    ready() {
        let slick = jQuery(this.audio.nativeElement);
        slick.owlCarousel({
            items: 1,
            center: true,
            loop: true,
            margin: 0,
            dots: true,
            nav: false
        });
    }
}