import { Component, OnInit, Input } from '@angular/core';

import { Poster } from '../../elements/poster';

@Component({
    selector: 'app-poster',
    templateUrl: './poster.component.html',
    styleUrls: ['./poster.component.scss']
})
export class PosterComponent implements OnInit {
    private _poster:Poster;

    @Input() set poster(value: Poster) {
        this._poster = value;
        // console.log( 'PosterComponent', this._poster );
        //this.getThumb();
    }

    constructor() {

    }

    ngOnInit() {
        // console.log('ngOnInit', 'init');
    }

}
