import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { ApiService } from '../../services/api.service';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {
    @Input()
        set name( inputName: any[] ) {
            this.searchBox["nativeElement"].value = inputName;
        }
    @Input('empty') emptyMessage;
    @Input('placeholder') placeholder;
    @Input('type') type;

    @Output() finded: EventEmitter<object> = new EventEmitter();

    @ViewChild('searchBox') searchBox: ElementRef;

    r$: Observable<any>;
    private searchTerms = new Subject<string>();
    private open:boolean = false;
    private searching:boolean = false;
    private empty:boolean = false;
    private findedList = [];

    constructor( private apiService: ApiService ) { }

    show() {
        this.open = true;
    }

    hide() {
        setTimeout( () => { 
            if ( this.empty || this.open ){
                this.open = false;
                this.finded.emit( {
                    name: this.searchBox["nativeElement"].value,
                    UID: false
                } );
            } 
        }, 200 );
    }

    doCallback( item ) {
        this.open = false;
        console.log('AutocompleteComponent', 'finded: ', item);
        this.searchBox["nativeElement"].value = item.name;
        this.finded.emit( item );
    }

    search( term:string ){
        console.log('AutocompleteComponent', 'search: ', term);
        if (term.length >= 3) { 
            this.empty = false;
            this.searching = true;
            this.open = true;
        } else {
            this.open = false;
        }
        this.searchTerms.next( term );
    }

    apiCall( term ){
        if (this.type == 'artist'){
            return this.apiService.searchArtists( term )
        } else {
            return this.apiService.searchVenues( term )
        }
    }

    ngOnInit(): void {
        this.r$ = this.searchTerms.pipe(
            // wait 300ms after each keystroke before considering the term
            debounceTime(300),

            // ignore new term if same as previous term
            distinctUntilChanged(),

            // switch to new search observable each time the term changes
            switchMap( (term: string) => this.apiCall( term ) ),
        );
        
        this.r$.subscribe(data => {
            this.searching = false;
            if (data && data.status == 200){
                this.empty = false;
                this.findedList = data.data;
                console.log(this.findedList);
            } else {
                this.empty = true;
                this.findedList = [];
            }
        });
    }
}
