import { Component, OnInit, ViewChild, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';

import { ApiService } from '../../services/api.service';
import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'app-vote',
    templateUrl: './vote.component.html',
    styleUrls: ['./vote.component.scss']
})
export class VoteComponent implements OnInit {
    @ViewChild('cover') cover : ElementRef;
    private averageWidth: number = 50;
    private voting: number = 0;
    private can: boolean = true;

    @Output() callback: EventEmitter<number> = new EventEmitter();
    @Input('average') average;
    @Input('voted') voted;

    constructor( private loadingService: LoadingService, private apiService: ApiService ) { }

    ngOnInit() {
        this.setVote( this.voted );
        this.setAverage( this.average );
    }

    send() {
        // console.log( 'VoteComponent', 'send' );
        
        this.voted = this.voting;
        
        this.callback.emit( this.voting );
    }
    
    setAverage( p ) {
        // console.log( 'VoteComponent', 'setWidth', p );

        p = ( p < 1 ) ? 0 : ( p > 99 ) ? 100 : p;

        this.averageWidth = 100 - p;
    }

    setVote( p ) {
        // console.log( 'VoteComponent', 'setWidth', p );

        p = ( p < 1 ) ? 0 : ( p > 99 ) ? 100 : p;

        this.voting = p;
    }

    @HostListener('mousemove', ['$event']) onMouseMove(e) {
        if (this.can){
            let p = 100 / this.cover.nativeElement.offsetWidth * e.layerX;
        
            this.setVote( p );
        }
    }

    @HostListener('mouseout', ['$event']) onMouseOut(e) {
        this.setVote( this.voted );
    }

}
