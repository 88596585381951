import { Component, OnInit } from '@angular/core';

import { MyComponent } from '../../components/my.component';

import { ApiService } from '../../services/api.service';
import { PageService } from '../../services/page.service';
import { LoadingService } from '../../services/loading.service';

//- ---

class Coords {
    public lat:number = 45.4774852;
    public lng:number = 9.1314699;

    constructor (){

    }
}

//- ---

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends MyComponent {
    public assets: {};
    public coords: Coords = null;
    artists: any = [];
    inPage: number = 3;

    constructor( private apiService: ApiService, public pageService: PageService, public loadingService: LoadingService ) {
        super( );

        this.pageService.setTitle( 'Segui la musica! Artisti, eventi, locali... cerca quello che ti interessa!' );
        this.pageService.setClass( 'home' );
        // console.log(this.coords);
        
        this.assets = {
            'artists': [],
            'locals': [],
            'events': [],
            'days': []
        };
    }

    ngOnInit() {
        this.loading();

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                let c = new Coords();
                c.lat = position.coords.latitude;
                c.lng = position.coords.longitude;
                this.coords = c;
                this.getHome();
            }, (error) => {
                this.coords = new Coords();
                this.getHome();
            });
            console.log("gino")
        } else {
            this.coords = new Coords();
            this.getHome();
        }
    }

    getHome(): void {
        this.apiService.getHome()
            .subscribe(data => {
                if (data.status == 200){
                    this.assets = data.data;
                } else {
                    this.assets = [];
                    this.loaded();
                }
            }
        );
    }

}
