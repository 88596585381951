import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'urlencode'
})
export class UrlencodePipe implements PipeTransform {

    transform(value: any): any {
        if (value && value != undefined) {
            value = value.replace(/ |\//gi, "_");
            return encodeURIComponent(value);
        } else {
            return '';
        }
    }

}
