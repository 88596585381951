import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import {DomSanitizer} from '@angular/platform-browser';

@Injectable()
export class PageService {
    private title: string = '';
    private mood: string = '';
    public class: string = '';
    public mediaUrl = 'https://assets.localero.it/_DEV';
    public v: number = 0;

    titleChange: Subject<any> = new Subject<any>();
    updateEvent: Subject<any> = new Subject<any>();

    constructor(public sanitizer: DomSanitizer)  {
        this.v = this.getTime();
    }

    setTitle(title: string, mood: string = '', type: string = ''){
        this.title = title;
        this.mood = mood;
        this.class = type;

        this.titleChange.next( { 
            "title": this.title, 
            "mood": this.mood, 
            "class": this.class 
        } );
    }

    eventUpdated(){
        // console.log( 'PageService', 'eventUpdated' );

        this.updateEvent.next();
    }

    setClass(value: string) {
        this.class = value;
        this.titleChange.next( { 
            "title": this.title,
            "mood": this.mood,
            "class": this.class 
        } );
    }

    thumb ( item ) {
        // console.log( 'PageService', 'get thumb', item.role);

        let thumb:string = null;

        switch ( item.role ) {
            case 'EVENT':
            case 'ARTIST':
            case 'VENUES':
                if(item.avatar){
                    thumb = this.mediaUrl+'/thumb/'+item.UID+'.jpg';
                } else {
                    thumb = this.mediaUrl+'/default/default.jpg';
                }
            break;
            default:
                // EVENT
                if(item.picture){
                    thumb = this.mediaUrl+'/event/'+item.id+'/preview/locandina.jpg';
                } else {
                    thumb = this.mediaUrl+'/default/event.jpg';
                }
                break;
        }

        // console.log( 'PageService', 'get thumb', thumb);
        return thumb+'?'+this.v;
    }

    url ( item ) {
        switch ( item.role ) {
            case 'ARTIST':
            case 'VENUES':
                return '/venue/' + item.id +'/'+ item.name;
            default:
                return '/artist/' + item.artist_id +'/'+ item.artist;
        }
    }

    public getTime(){
        return Date.now() /1000 |0;
    }
}
