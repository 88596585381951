import { Component, OnInit } from '@angular/core';
import { MyComponent } from '../../components/my.component';
import { ApiService } from '../../services/api.service';
import { PageService } from '../../services/page.service';
import { LoadingService } from '../../services/loading.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent extends MyComponent {

  constructor( private apiService: ApiService, public t: PageService, private route: ActivatedRoute, public loadingService: LoadingService ) {
    super(  );
    this.t.setTitle('REGOLAMENTO');
  }

  ngOnInit() {
  }

}
