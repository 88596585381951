import { Component, ViewChild, OnInit, Input, Output, EventEmitter, ViewEncapsulation, Inject, LOCALE_ID, NgModule } from '@angular/core';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';


declare var slick;
declare var jQuery;

@Component({
    selector: 'app-stripe-calendar',
    templateUrl: './stripe-calendar.component.html',
    styleUrls: ['./stripe-calendar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class StripeCalendarComponent implements OnInit {
    @Input('days') days;
    
    @Output() changeDay: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('slick') slickElement: any;

     

    constructor(@Inject(LOCALE_ID) private locale: string) {
    }

    ngOnInit() {
    }

    clickDay(day) {
        this.changeDay.emit(day);
    }

    isReady() {
        let owlCalendar = jQuery(this.slickElement.nativeElement);
        owlCalendar.owlCarousel({
            items:7,
            center: true,
            loop:false,
            margin:0,
            dots: false,
            nav: false,
            responsive : {
                0 : {
                    items:3,
                },
                480 : {
                    items:3,
                },
                768 : {
                    items:5,
                },
                1024 : {
                    items:7,
                }
            }
            //fluidSpeed : true,
            //smartSpeed : 1
        });

        owlCalendar.find('.owl-item').click(function(){
            let i = jQuery(this).index();
            owlCalendar.trigger('to.owl.carousel', [i]);
        });
    }
}
