import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PageService } from '../../services/page.service';

import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-chessboard',
    templateUrl: './chessboard.component.html',
    styleUrls: ['./chessboard.component.scss']
})
export class ChessboardComponent implements OnInit {
    @Output() updated: EventEmitter<any> = new EventEmitter();

    @Input()
        set list( inputList: any[] ) {
            this.ready = ( inputList === null ) ? false : true;
            this.item = inputList;
            this.empty = (this.item) ? this.item.length == 0 : true;
            this.separateEvents();
        }
    
    private ready: boolean = false;
    private empty: boolean = false;
    item: any[] = null;

    private mediaUrl:string = '';
    private myId:number = null;

    constructor(private pageService: PageService, private Auth: AuthService) {
        this.mediaUrl = this.pageService.mediaUrl;
    }

    today = new Date(); 
    pastEvents = [];
    nextEvents = [];




    // funzione che itera tutti gli event e li divide per eventi passati ed eventi prossimi
    separateEvents() {
        this.item.forEach(event => {
            const dateInMilliseconds = event.when;
            const formattedDate = new Date(dateInMilliseconds);
            
            if (formattedDate < this.today) {
                this.pastEvents.push(event)
            } else {
                this.nextEvents.push(event)
            }
        })        
    }
    
    ngOnInit() {
        this.separateEvents();
    }       

    color = 'accent';
    checked = false;

    changed(){
        this.checked = !this.checked;
        
        // ogni volta che richiamo la funzione, si svuotano entrambi gli array e si riempe solo quello che ci interessa
        this.pastEvents = [];
        this.nextEvents = [];
        this.separateEvents();
    }

}
