export class User {
    UID: number;
    authenticated: string;
    role: string;
    name: string;
    email: string;
    token: string;
    rand: number;
}

export class Recovery {
    UID: number;
    token: string;
}