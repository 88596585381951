import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { User, Recovery } from '../_models/user';

@Injectable()
export class AuthService {
    private user:User; 
    private recovery:Recovery;
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;


    constructor( private router: Router, private http: HttpClient, private route: ActivatedRoute) {
        this.user = JSON.parse( localStorage.getItem('currentUser') );
        this.currentUserSubject = new BehaviorSubject<User>( this.user );
        this.currentUser = this.currentUserSubject.asObservable();
        this.user = this.user || new User();
        this.user.rand = Date.now();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    setUser = function( mUser ) {
        // console.log('AuthService', 'setUser', mUser);

        var rand = new Date().getTime();

        this.user.email = mUser.email;
        this.user.token = mUser.SID;
        this.user.authenticated = (mUser.SID)?true:false;
        this.user.UID = mUser.UID;
        this.user.role = mUser.role;
        this.user.name = mUser.name;
        this.user.rand = Date.now();

        localStorage.setItem('currentUser', JSON.stringify(this.user));
        this.currentUserSubject.next(this.user);

        //this.router.navigate(['/']);
    }

    clearUser = function() {
        this.user.email = false;
        this.user.token = false;
        this.user.authenticated = false;
        this.user.UID = false;
        this.user.role = false;
        this.user.name = false;
        this.user.thumb = false;

        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);

        // this.router.navigate(['/']);
    }
    
    isMe = function( id ) {
        var r = (this.user.authenticated && this.user.UID===id)?true:false;
        return r;
    }

    isLogged = function() {
        var r = (this.user.authenticated) ? this.user.UID : null;
        return r;
    }

    getUser = function() {
        return this.user;
    }

    getToken = function() {
        // console.log('AuthService', 'getToken', 'user', this.user);
        // console.log('AuthService', 'getToken', 'recovery', this.recovery);

        if ( this.user.UID ){
            return {'email': this.user.email, 'token': this.user.token};
        } else {
            this.route.params.subscribe( params => {
                // console.log('AuthService', 'getToken', 'route', 'params', params);
    
                if ( "recovery-token" in params ){
                    this.recovery.UID = params['id'];
                    this.recovery.token = params['recovery-token'];
                }
            });

            if ( this.recovery ){
                return {'id': this.recovery.UID, 'token': this.recovery.token};
            } else {
                return {'email': '', 'token': ''};
            }
        }
    }
}
