import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

import { PageService } from '../../services/page.service';

declare var jQuery;

@Component({
    selector: 'app-photo-wall',
    templateUrl: './photo-wall.component.html',
    styleUrls: ['./photo-wall.component.scss']
})
export class PhotoWallComponent implements OnInit {
    @Input('list') list;
    @Input('edit') edit: boolean = false;
    @Input('class') class: string = 'full';

    @ViewChild('carousel') carousel: ElementRef;

    private mediaUrl: string = '';
    private src: string = '';
    private zoomed: boolean = false;
    private owl: any;

    constructor(private tool: PageService) {
        this.mediaUrl = this.tool.mediaUrl;
    }

    ngOnInit() {
    }

    ready() {
        // console.log('PhotoWallComponent', 'READY');

        this.owl = jQuery( this.carousel.nativeElement );
        this.owl.owlCarousel({
            items:1,
            center: false,
            loop:false,
            margin:0,
            dots: false,
            nav: true
        });/**/

        jQuery( this.carousel.nativeElement ).find('button').click(function( event ){
            event.stopPropagation();
        });
    }

    zoom( src ) {
        this.zoomed = true;
        this.owl.trigger('refresh.owl.carousel');
        //this.src = src;
    }

    deZoom( src ) {
        this.zoomed = false;
    }

    photoAdded( src ) {
        // console.log('PhotoWallComponent', 'photoAdded', src.media.PHOTO);

        this.list = src.media.PHOTO;
    }

}