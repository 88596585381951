import { Directive, Input, Output, EventEmitter } from '@angular/core';

@Directive({ 
    selector: '[appIsLast]' 
})
export class IsLastDirective {
    @Input() appIsLast: boolean;
    @Output() appIsLastDone: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() { }

    ngOnInit() {
        if (this.appIsLast) {
            this.appIsLastDone.emit(true);
        }
        if ( this.appIsLast == undefined ){
            console.log('ERROR in *ngFor configuration, missing "last" init');
        }
    }
}