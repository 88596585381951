import { Component, OnInit, Renderer2, ViewChild, ElementRef, Input } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { ApiService } from '../../services/api.service';
import { PageService } from '../../services/page.service';

import { Items } from './items';
import { SearchResult } from './items';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
    @ViewChild('searchBox') searchBox: ElementRef;

    //items$: Observable<Items[]>;
    r$: Observable<SearchResult>;
    private searchTerms = new Subject<string>();
    private open:boolean = false;
    private searching:boolean = false;

    items: Items = {
        events: [],
        artists: [],
        locals: [],
    };

    private mediaUrl:string;

    constructor( public pageService: PageService, private apiService: ApiService, private renderer: Renderer2 ) {
        this.mediaUrl = this.pageService.mediaUrl;
    }

    search( term:string ){
        console.log('search: '+term);
        if (term.length) { this.searching = true; }
        this.searchTerms.next(term);
    }

    openResults( ){
        this.open = true;
        this.renderer.addClass(document.body, 'modal-open');
        this.renderer.selectRootElement(this.searchBox["nativeElement"]).focus();
        // console.log(this.open);
    }

    stopPropagation(e: Event){
        //e.preventDefault();
        e.stopPropagation();  // <- that will stop propagation on lower layers
        // console.log('STOPPED');
    }

    closeResults(e: Event){
        this.open = false;
        this.renderer.removeClass(document.body, 'modal-open');
    }

    ngOnInit(): void {
        this.r$ = this.searchTerms.pipe(
          // wait 300ms after each keystroke before considering the term
          debounceTime(300),
    
          // ignore new term if same as previous term
          distinctUntilChanged(),
    
          // switch to new search observable each time the term changes
          //switchMap((term: string) => this.apiService.searchTerm( term )),
          switchMap( (term: string) => this.apiService.searchTerm( term )),
        );
        
        this.r$.subscribe(data => {
            this.searching = false;
            if (data && data.status == 200){
                this.items = data.data;
                // console.log(this.items);
            } else {
                this.items = {
                    events: [],
                    artists: [],
                    locals: [],
                };
            }
        });
    }
}