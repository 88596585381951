import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-artists-wall',
    templateUrl: './artists-wall.component.html',
    styleUrls: ['./artists-wall.component.scss']
})
export class ArtistsWallComponent implements OnInit {
    @Input('list') list;  

    constructor() {}

    ngOnInit() {
    }

}
