import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

import { PageService } from '../../services/page.service';
import { ApiService } from '../../services/api.service';

declare var jQuery;

@Component({
  selector: 'app-yt-wall',
  templateUrl: './yt-wall.component.html',
  styleUrls: ['./yt-wall.component.scss']
})
export class YtWallComponent implements OnInit {
    @Input('list') list;
    @Input('edit') edit: boolean = false;
    @Input('class') class: string = 'full';

    @ViewChild('yt') yt: ElementRef;

    private src: string = '';
    private zoomed: boolean = false;
    private empty: boolean = true;
    private mediaUrl:string = '';
    private ytId:string = '';

    private isFree:boolean = false;

    constructor(private apiService: ApiService, private tool: PageService) {
        this.mediaUrl = this.tool.mediaUrl;
    }

    ngOnInit() {
        // console.log('YtWallComponent', this.list.length);
    }

    ready() {
        // console.log('YtWallComponent', 'ready');

        let slick = jQuery(this.yt.nativeElement);
        slick.owlCarousel({
            items: 2,
            // center: true,
            loop:true,
            margin:15,
            dots: true,
            nav: false
        });

        setTimeout(
            function(){
                slick.trigger('refresh.owl.carousel', [300]);
            }, 1000
        );
        

        /* for (let i = 0; i < this.list.length; i++) {
            console.log('YtWallComponent', this.list.length);

            let media = this.list[i].media;
            
            this.apiService.getYt( media ).subscribe(
                (res) => {
                    //this.onSuccess( res );
                },
                (err) => {
                    //this.onError();
                }
            );
        } */


        this.empty = false;
        // console.log('YtWallComponent', this.list.length);
    }

    notFilled() : boolean {
        return this.ytId.length > 5 ? null : true;
    }

    zoom( src ) {
        this.zoomed = true;
        this.src = src;
    }

    deZoom() {
        this.zoomed = false;
        this.src = '';
    }

    //- ADD VIDEO ---
    private onSuccess( res ) {
        // console.log('YtWallComponent', 'onSuccess', res.data.media.VIDEO);

        this.list = res.data.media.VIDEO;

        let slick = jQuery(this.yt.nativeElement);
        //slick.owlCarousel('destroy');
        slick.trigger('destroy.owl.carousel');
        setTimeout(
            function(){
                slick.owlCarousel({
                    items: 2,
                    // center: true,
                    loop:true,
                    margin:15,
                    dots: true,
                    nav: false
                });
            }, 1000
        );
        /*slick.owlCarousel({
            items: 2,
            // center: true,
            loop:true,
            margin:15,
            dots: true,
            nav: false
        });*/
    }

    private onError() {
    }

    addVideo() {
        // console.log('YtWallComponent', 'processFile', this.ytId);

        this.apiService.addVideo(this.ytId).subscribe(
            (res) => {
                this.onSuccess( res );
            },
            (err) => {
                this.onError();
            });

        return false;
    }

}
