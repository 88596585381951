import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

import { PageService } from '../../services/page.service';

@Component({
    selector: 'app-dot-wall',
    templateUrl: './dot-wall.component.html',
    styleUrls: ['./dot-wall.component.scss']
})
export class DotWallComponent implements OnInit {
    @Input('list') list;
    @Input('class') class: string = 'default';

    @Output() ready: EventEmitter<string> = new EventEmitter<string>();

    private mediaUrl:string = '';

    constructor(private pageService: PageService) { 
        this.mediaUrl = this.pageService.mediaUrl;
    }

    ngOnInit() {
    }

    isReady() {  
        this.ready.emit();
    }

    flip( artist ) {  
        artist.flipped = !artist.flipped;
    }
}
