import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { PageService } from '../../services/page.service';
import { AuthService } from '../../services/auth.service';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-talk',
  templateUrl: './talk.component.html',
  styleUrls: ['./talk.component.scss']
})
export class TalkComponent implements OnInit {
    private talkForm: FormGroup;
    private status:string = "ready";
    private active:boolean = false;

    constructor(public pageService: PageService, private apiService: ApiService, fb: FormBuilder, private renderer: Renderer2, 
        private Auth: AuthService, private loadingService: LoadingService) {

        this.talkForm = fb.group({
            name: ["", Validators.required],
            email: ["", Validators.email],
            message: ["", Validators.required]
        });

    }

    ngOnInit() {
        // this.Auth.initUser();
    }

    open() {
        this.active = true;
        this.renderer.addClass(document.body, 'modal-open');
    }

    close() {
        this.active = false;
        this.renderer.removeClass(document.body, 'modal-open');
    }

    loginMe() {
        console.log(this.talkForm.value);

        let json = this.talkForm.value;

        this.loadingService.loading();

        this.apiService.login(json)
            .subscribe( json => {
                console.log(json);
                if (json && json.status == 200){
                    this.Auth.setUser( json.data.user );
                    this.close();
                    this.loadingService.loaded();
                } else {
                    this.loadingService.error('Username e/o password errate', 'I dati inseriti non coincidono con quelli indicati in fase di registrazione');
                    this.status = 'error';
                    console.log('error login');
                }
            }
        );
    }

    swap() {
        console.log('swap');
        if (this.active){
            this.close();
        } else {
            this.open();
        }
        
        return false;
    }

}
