import { Component, OnInit, Input } from '@angular/core';

import { PageService } from '../../services/page.service';

@Component({
    selector: 'app-card-small',
    templateUrl: './card-small.component.html',
    styleUrls: ['./card-small.component.scss']
})
export class CardSmallComponent implements OnInit {
    @Input('card') card;

    name: string;
    description: string;
    thumb: string;
    link: string;

    constructor(public t: PageService) { }

    ngOnInit() {
        this.card.role = 'VENUES';
        this.name = this.card.name;
        this.description = this.card.address;
        this.thumb = this.t.thumb( this.card );
        this.link = this.t.url( this.card );
    }

}