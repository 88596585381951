import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'notEmpty'
})
export class NotEmptyPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        console.log(value);

        /*let keys = [];
        for (let key in value) {
            keys.push(key);
        }
        return keys;/** */
    }

}
