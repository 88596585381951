import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MyComponent } from '../../components/my.component';

import { ApiService } from '../../services/api.service'
import { PageService } from '../../services/page.service';
import { LoadingService } from '../../services/loading.service';

import { districts } from '../../_data/districts';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent extends MyComponent {

    private types;
    private music;
    private thumb;
    private aboutForm: FormGroup;
    districts = districts;

    constructor(fb: FormBuilder, private apiService: ApiService, public t: PageService, public loadingService: LoadingService) {
        super( );
        this.t.setTitle( 'Il mio profilo' );

        this.aboutForm = fb.group({
            privacy: ["", Validators.required],
            name: ["", Validators.required],
            excerpt: ["", Validators.required],
            exhibition: ["", Validators.required],
            mood: ["", Validators.required],
            biography: ["", Validators.required],
            crew: ["", Validators.required],
            original: ["", Validators.required],
            cover: ["", Validators.required],
            instrumentation: ["", Validators.required],
            willingness: ["", Validators.required],
            city_id: ["", Validators.required],
            www: ["", Validators.required],
            strumentation: ["", Validators.required],
            facebook: ["", Validators.required],
            twitter: ["", Validators.required],
            soundcloud: ["", Validators.required],
            youtube: ["", Validators.required]
        });

    }

    ngOnInit() {
        this.loading();
        this.load();
    }

    setFormVal( val ) {
        this.aboutForm.get('privacy').setValue( val.privacy );
        this.aboutForm.get('name').setValue( val.name );
        this.aboutForm.get('excerpt').setValue( val.excerpt );
        this.aboutForm.get('exhibition').setValue( val.exhibition );
        this.aboutForm.get('mood').setValue( val.mood );
        this.aboutForm.get('biography').setValue( val.biography );
        this.aboutForm.get('crew').setValue( val.crew );
        this.aboutForm.get('original').setValue( val.original );
        this.aboutForm.get('cover').setValue( val.cover );
        this.aboutForm.get('instrumentation').setValue( val.instrumentation );
        this.aboutForm.get('willingness').setValue( val.willingness );
        this.aboutForm.get('city_id').setValue( val.city_id );
        this.aboutForm.get('www').setValue( val.www );
        this.aboutForm.get('facebook').setValue( val.facebook );
        this.aboutForm.get('twitter').setValue( val.twitter );
        this.aboutForm.get('soundcloud').setValue( val.soundcloud );
        this.aboutForm.get('youtube').setValue( val.youtube );
    }

    load() {
        var data = {'single':this.aboutForm.value, 'types':[], 'music':[]};
        
        this.apiService.preferenceLoad( )
            .subscribe( json => {
                // console.log('AboutComponent', json);
                if (json && json.status == 200){
                    this.setFormVal( json.data.single );
                    this.thumb = this.t.mediaUrl+'/avatar/'+json.data.single.UID+'.jpg';
                    this.types = json.data.types;
                    this.music = json.data.music;
                    this.loaded();
                } else {
                    this.error('Errore', 'Si è verificato un errore durente il carcamento del profilo');
                    // console.log('error');
                }
            }
        );

        return false;
    }

    editingStatus: string = null;
    message: string = '';

    save() {
        console.clear();
        var data = {'single':this.aboutForm.value, 'types':this.types, 'music':this.music};

        // this.loading();

        this.apiService.preferenceSave( data )
            .subscribe( json => {
                if (json && json.status == 200){
                    this.setFormVal( json.data.single );
                    this.editingStatus = 'success';
                    this.message = 'I dati sono stati salvati correttamente!';
                    this.loaded();
                    console.log('è andato tutto bene');
                    
                } else {
                    this.editingStatus = 'error';
                    this.message = 'Attenzione! Correggere alcuni dati';
                    this.error('Errore', 'Si è verificato un errore durente il salvataggio del profilo');
                }
            }
        );

        return false;
    }

}
