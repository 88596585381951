import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MyComponent } from '../../components/my.component';

import { ApiService } from '../../services/api.service';
import { PageService } from '../../services/page.service';
import { LoadingService } from '../../services/loading.service';

import { Event } from './items';

@Component({
	selector: 'app-event',
	templateUrl: './event.component.html',
	styleUrls: ['./event.component.scss']
})
export class EventComponent extends MyComponent {
	id: number;
	item: any;
	poster: any;
	venue: any;
	details: any;
	coords: any = null;
	assets: string;
	openExcerpt: boolean = false;
	private sub: any;

	constructor( private apiService: ApiService, public t: PageService, private route: ActivatedRoute, public loadingService: LoadingService ) {
		super(  );
		this.assets = this.t.mediaUrl;
		this.t.setTitle( 'Events' );
	}

	ngOnInit() {
		this.loading();

		this.sub = this.route.params.subscribe(params => {
			this.id = +params['id'];

			// In a real app: dispatch action to load the details here.
			this.getEvent();
		 });
	}

	getEvent(): void {
		this.apiService.getEvent( this.id )
			.subscribe(data => {
				if (data.status == 200) {
					// console.log(data.data);
					this.item = data.data;

					this.t.setTitle( this.item.event.title );
					this.poster = this.getPoster();
					this.details = this.getDetails();
					this.coords = { "lat": this.item.event.lat, "lng":this.item.event.lng };
					// console.log( 'EventComponent', 'getEvent 1' );
					this.getVenue();
					// console.log( 'EventComponent', 'getEvent 2' );
					this.loaded();
				} else {
					this.item = null;
				}
			});
	}

	getVenue(): any {
		if (!this.item.local) return false;

		this.coords = { "lat": this.item.local.lat, "lng":this.item.local.lng };

		this.venue = {
			name: this.item.local.name,
			description: this.item.local.music,
			thumb: this.t.thumb( this.item.local ),
			link: this.t.url( this.item.local ),
		}

		return true;
	}

	getPoster(): any {
		return {
			"locandina": (this.item.event.locandina == 1 || this.item.event.locandina == '1') ? true : false,
			"thumb": this.t.thumb( this.item.event ),
			"when": this.item.event.when,
			"where": this.item.event.where,
			"title": this.item.event.title,
			"address": (this.item.event.address && this.item.event.address.length > 0) ? this.item.event.address : this.item.local.address
		}	
	}

	getDetails(): any {
		let price = '';
		switch (this.item.event.access) {
			case 'FREE':
				price = 'ingresso libero';
				break;
			case 'DRING':
				price = !( (this.item.event.price + 0) > 0 )  ? 'ingresso libero' : 'consumazione obbligatoria €'+this.item.event.price;
				break;
			case 'PRIVATE':
				price = 'evento privato';
				break;
			case 'TICKET':
			default:
				price = !( (this.item.event.price + 0) > 0 ) ? 'ingresso libero' : 'ingresso a pagamento €'+this.item.event.price;
				break;
		}

		return {
			"quando": this.item.event.when,
			"dove": this.item.event.where,
			"presso": price,
		}	
	}

}
