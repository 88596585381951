import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';

import { PageService } from '../../services/page.service';
import { User } from '../../_models/user';

@Component({
    selector: 'app-nav-badge',
    templateUrl: './nav-badge.component.html',
    styleUrls: ['./nav-badge.component.scss']
})
export class NavBadgeComponent implements OnInit {
    @Input() user:User;

    private active:boolean = false;
    private src:string;

    constructor( private Auth: AuthService, public t: PageService ) {
        
    }

    ngOnInit() {
        this.user = this.Auth.getUser();
        // console.log( 'NavBadgeComponent', this.user );
        this.src = this.t.mediaUrl+'/avatar/'+this.user.UID+'.jpg?r='+this.user.rand;
    }

    open() {
        this.active = true;
    }

    close() {
        this.active = false;
    }

    toggle() {
        if (this.active){
            this.close();
        } else {
            this.open();
        }
        
        return false;
    }

    logOut() {
        this.Auth.clearUser();
    }
}
