import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-events-wall',
    templateUrl: './events-wall.component.html',
    styleUrls: ['./events-wall.component.scss']
})
export class EventsWallComponent implements OnInit {
    @Input('title') title:string;
    @Input('description') description:string;
    @Input('list') list:any;
    @Output() updated: EventEmitter<any> = new EventEmitter();

    public ready: boolean = false;

    constructor() {
    }

    ngOnInit() {
        this.ready = true;

        // console.log('EventsWallComponent', this.list);
    }

}
