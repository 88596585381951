import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../services/api.service';

@Component({
    selector: 'app-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
    event: any = [];
    id: number;

    @Input('details') details;
    @Input('labels') labels;

    constructor(private apiService: ApiService) { }

    ngOnInit() {
        console.clear();
        this.getEvent();
        console.log(this.event);
        
    }

    getEvent(): void {
		this.apiService.getEvent( this.id )
			.subscribe(data => {
				if (data.status == 200) {
					this.event = data.data;
				}
			});
	}

}
