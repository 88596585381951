import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MyComponent } from '../../components/my.component';

import { ApiService } from '../../services/api.service';
import { PageService } from '../../services/page.service';

declare var jQuery;

@Component({
  selector: 'app-venue',
  templateUrl: './venue.component.html',
  styleUrls: ['./venue.component.scss']
})
export class VenueComponent extends MyComponent {
    id: number;
    assets: string;
    venue: any = {
		"artists": [],
		"events": []
	};
    thumb: any = null;
	details: any = null;
	coords: any;
	openExcerpt: boolean = false;
	genres: any = [];

	@ViewChild('venues') venues: ElementRef;
	@ViewChild('artistsWall') artistsWall: ElementRef;

    constructor( private apiService: ApiService, public t: PageService, private route: ActivatedRoute ) {
		super();
		this.assets = this.t.mediaUrl;
		this.t.setTitle( 'loading...' );
	}

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.id = +params['id'];

            // In a real app: dispatch action to load the details here.
            this.getVenue();	
			this.getDetails();				
        });

    }

    getVenue(): void {
		this.apiService.getVenue( this.id )
			.subscribe(data => {
				if (data.status == 200) {
					this.venue = data.data;
					this.t.setTitle( this.venue.name, this.venue.excerpt );
					this.thumb = this.t.thumb( this.venue );
					this.details = this.getDetails();		
					this.coords = { "lat": this.venue.lat, "lng": this.venue.lng };
					this.loaded();
				} else {
					this.venue = null;
				}
			});
	}

	getDetails(): any {

		// Questa funzione ora serve solo per pushare nell'array "genres" il contenuto di venue.music, una stringa contentente i generi di quel locale
		this.genres.push(this.venue.music);


		// return {
			// 'Genere': this.venue.music
			// "Apertura": this.venue.opening_time,
			// "Inizio concerti": this.venue.event_time,
			// "Palco": this.venue.stage,
			// "Fonico/Service": this.venue.service,
			// "Sound check": this.venue.checksound_time,
		// }	
	}

	artistWallIsReady() {
		console.log('IS READY artistWallReady');
		
		let slickVenues = jQuery(this.artistsWall.nativeElement).find('ul');

		if( jQuery(slickVenues).find('li').length > 1 ){
			slickVenues.owlCarousel({
				items:2,
				center: false,
				loop: false,
				margin: 0,
				dots: false,
				nav: true,
				responsive : {
					0 : {
						items:1
					},
					500 : {
						items:2
					},
					768 : {
						items:2
					},
					1170 : {
						items:2
					}
				}
			});/** */
		}
        
    }


}
