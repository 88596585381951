import { Component, NgModule, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { NgModel } from "@angular/forms";
import { NullTemplateVisitor } from "@angular/compiler";
import { NumberSymbol } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    selector: "app-vote-event",
    templateUrl: "./vote-event.component.html",
    styleUrls: ["./vote-event.component.scss"],
})
export class VoteEventComponent implements OnInit {
    id: number;
    eventInfo: any = [];
    user: any = [];
    private sub: any;
    selectedVote: number = 0;
    responseMsg: any;

    constructor(
        private apiService: ApiService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe((params) => {
            this.id = +params["id"];
        });
        this.eventGet();
        this.getUser();
    }

    eventGet(): void {
        this.apiService.getEvent(this.id).subscribe((data) => {
            if (data.status == 200) {
                this.eventInfo = data.data;
            } else {
                this.eventInfo = null;
            }
        });
    }

    getUser(): void {
        this.apiService.userGet().subscribe((data) => {
            if (data.status == 200) {
                this.user = data.data;
            } else {
                this.user = null;
            }
        });
    }

    voteArtistEvent(
        event_id: number,
        artist_id: number,
        vote: number,
        geolocation: any
    ) {
        return this.apiService.voteArtistEvent(
            event_id,
            artist_id,
            vote,
            geolocation
        );
    }

    onSubmit() {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                this.voteArtistEvent(
                    this.eventInfo.event.id,
                    this.eventInfo.others[0].UID,
                    this.selectedVote,
                    {
                      lat: position.coords.latitude,
                      lng: position.coords.longitude,
                    }
                ).subscribe((data) => {
                    this.responseMsg = data;
                });
            },
            (error) => {
                console.log(error);
                this.voteArtistEvent(
                  this.eventInfo.event.id,
                  this.eventInfo.others[0].UID,
                  this.selectedVote,
                  null
                );
            }
        );
    }
}
