import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MyComponent } from '../../components/my.component';

import { ApiService } from '../../services/api.service';
import { LoadingService } from '../../services/loading.service';
import { AuthService } from '../../services/auth.service';
import { PageService } from '../../services/page.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends MyComponent {

    private settingsForm: FormGroup;

    constructor( private fb: FormBuilder, private apiService: ApiService, public loadingService: LoadingService, public t: PageService, 
        private Auth: AuthService, private route: ActivatedRoute ) {
        super();
        this.t.setTitle( 'Modifica password' );
        
        this.settingsForm = fb.group({
            password: ["", Validators.required],
            //password_confirm: ["", Validators.required]
        });

    }

    ngOnInit() {
        this.settingsForm.reset();
        
        if ( !this.Auth.isLogged() ){
            this.route.params.subscribe( params => {
                console.log('SettingsComponent', 'ngOnInit', 'route', 'params', params);
                
                if ( "recovery-token" in params ){            
                    this.loginMeCall( { 'UID':params['id'], 'token':params['recovery-token']} );
                }
            });
        }
    }

    loginMeCall( json ) {
        this.apiService.login(json)
            .subscribe( json => {
                console.log('NavLoginComponent', 'loginMeCall', json);
                if (json && json.status == 200){
                    this.Auth.setUser( json.data.user );
                    this.loadingService.loaded();
                } else {
                    this.loadingService.error('Recupero password', 'Il link inserito non è più valido, se non hai ancora recuperato la tua password richiedi nuovamente il recupero, ti invieremo una nuova mail.');
                    this.status = 'error';
                    console.log('error login');
                }
            }
        );
    }

    save() {
        console.log('SettingsComponent', 'save', this.settingsForm.value);

        this.loadingService.loading();

        this.apiService.accountSave( this.settingsForm.value )
            .subscribe( json => {
                console.log(json);
                if (json && json.status == 200){
                    this.settingsForm.reset();
                    this.loadingService.success('Password modificata', 'La password è stata modificata, ricordati di usare la nuova password per i prossimi accessi.');
                } else {
                    this.loadingService.error('Errore', 'Si è verificato un errore durente il salvataggio.');
                    console.log('error');
                }
            }
        );

        return false;
    }


}
