import { Component, ViewChild, OnInit, SimpleChanges, Input } from '@angular/core';

import { PageService } from '../../services/page.service';

import { UrlencodePipe } from '../../pipes/urlencode.pipe';
import { CropPipe } from '../../pipes/crop.pipe';

declare var google;

@Component({
    selector: 'app-maps2',
    templateUrl: './maps2.component.html',
    styleUrls: ['./maps2.component.scss'],
    providers: [ UrlencodePipe ]
})
export class MapsComponent2 implements OnInit {
    @Input('coords') coords;
    @Input('markers') markers;
    
    @ViewChild('gmap') gmapElement: any;
    //initialCenter = google.maps.LatLng;
    
    map: any;
    icon = {
        path: "M18.5,1.7c-7.2,0-13,5.8-13,13c0,3.5,1.4,6.7,3.7,9.1l9.3,11.5l9.4-11.5c2.3-2.3,3.7-5.5,3.7-9.1C31.5,7.5,25.7,1.7,18.5,1.7z M18.5,24.9c-5.5,0-10-4.5-10-10s4.5-10,10-10c5.5,0,10,4.5,10,10S24,24.9,18.5,24.9z",
        fillColor: '#ddd',
        fillOpacity: 1,
        anchor: {},
        strokeWeight: 0,
        scale: 1
    };
    iconLocalero = {
        path: "M18.5,1.7c-7.2,0-13,5.8-13,13c0,3.5,1.4,6.7,3.7,9.1l9.3,11.5l9.4-11.5c2.3-2.3,3.7-5.5,3.7-9.1C31.5,7.5,25.7,1.7,18.5,1.7z M18.5,24.9c-5.5,0-10-4.5-10-10s4.5-10,10-10c5.5,0,10,4.5,10,10S24,24.9,18.5,24.9z",
        fillColor: '#FF850F',
        fillOpacity: 1,
        anchor: {},
        strokeWeight: 0,
        scale: 1
    };
    markersSetted: any = [];
    
    constructor(public pageService: PageService, private urlencode: UrlencodePipe) {
    }

    ngOnInit() {
        this.impostaCentroIniziale();
    }

    impostaCentroIniziale() {
        /*
        if (navigator.geolocation) {
            if(!this.coords) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.initialCenter = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                    this.loadMap();
                }, (error) => {
                    console.error('Errore nel recupero della localizzazione');
                    this.initialCenter = new google.maps.LatLng(this.coords && this.coords.lat ? this.coords.lat : 45.4774852, this.coords && this.coords.lng ? this.coords.lng : 9.1314699);
                    this.loadMap();
                });
            }else {
                this.initialCenter = new google.maps.LatLng(this.coords && this.coords.lat ? this.coords.lat : 45.4774852, this.coords && this.coords.lng ? this.coords.lng : 9.1314699);
                this.loadMap();
            }
        } else {
            this.initialCenter = new google.maps.LatLng(this.coords && this.coords.lat ? this.coords.lat : 45.4774852, this.coords && this.coords.lng ? this.coords.lng : 9.1314699);
            this.loadMap();
        }*/
        //this.initialCenter = new google.maps.LatLng(this.coords && this.coords.lat ? this.coords.lat : 45.4774852, this.coords && this.coords.lng ? this.coords.lng : 9.1314699);
        this.loadMap();
        /*console.clear();
        console.log(this.initialCenter);*/
        
    }

    loadMap() {
        let styles = [{"featureType":"all","elementType":"labels.text.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"color":"#000000"},{"lightness":13}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#144b53"},{"lightness":14},{"weight":1.4}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#08304b"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#0c4152"},{"lightness":5}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#0b434f"},{"lightness":25}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"road.arterial","elementType":"geometry.stroke","stylers":[{"color":"#0b3d51"},{"lightness":16}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#000000"}]},{"featureType":"transit","elementType":"all","stylers":[{"color":"#146474"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#021019"}]},{"featureType": "poi","stylers": [{ "visibility": "off" }]}];
        let mapOptions = {
            center: new google.maps.LatLng(this.coords && this.coords.lat ? this.coords.lat : 45.4774852, this.coords && this.coords.lng ? this.coords.lng : 9.1314699),
            zoom: 10,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControl: false,
            scrollwheel: false,
            scaleControl: false,
            zoomControl: true,
            disableDefaultUI: true,
            styles: styles
        };

        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);
        
        /*var center = function(){
            google.maps.event.trigger(this.map, 'resize');
            this.map.setCenter(initialCenter);
        }*/

        this.addMarkers();
    }

    ngOnChanges(obj: SimpleChanges) {
        // console.log( obj.markers );

        this.addMarkers();
    }

    //- ---

    addMarkers() {
        let infowindow = new google.maps.InfoWindow({
            content: '',
            maxWidth: 250,
            width: 250,
            height: 200
        });

        //remove prev markers
        for (let i = 0; i <  this.markersSetted.length; i++) {    
            this.markersSetted[i].setMap(null);
        }

        //add new markers
        for (let i = 0; i < this.markers.length; i++) {
            let item = this.markers[i];
            let icon = null;

            if ( item.artist_id )
                icon = this.iconLocalero;
            else 
                icon = this.icon;

            icon.anchor = new google.maps.Point(19, 32);
            let marker = new google.maps.Marker({
                map: this.map,
                icon: icon,
                animation: google.maps.Animation.DROP,
                position: new google.maps.LatLng(item.lat, item.lng)
            });
            let media_url = this.pageService.mediaUrl;
            let page_url = '/event/' + (item.event_id) + '/' + this.urlencode.transform(item.title);

            let content = '<div class="map-popUp">';
            content += '<a href="'+ page_url +'"><figure><img src="' + media_url + '/avatar/' + (item.UID) + '.jpg"></figure>';
            content += '<p class="venue-events">'+item.title+'</p></a></div>';

            google.maps.event.addListener(marker, 'click', function() {
                infowindow.close();
                infowindow.setContent(content);
                infowindow.open(this.map, marker);
            });

            google.maps.event.addListener(infowindow, 'domready', function() {
                return true;
            });

            this.markersSetted.push(marker);
        }
    }


}
