import { Component, OnInit } from '@angular/core';

import { PageService } from '../../services/page.service';

@Component({
    selector: 'app-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

    constructor(  public t: PageService ) { }

    ngOnInit() {
        this.t.setTitle( 'Privacy', '' );
    }

}
