import { Component, OnInit, Renderer2, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { formatDate } from '@angular/common';

import { ApiService } from '../../services/api.service';
import { PageService } from '../../services/page.service';
import { AuthService } from '../../services/auth.service';
import { LoadingService } from '../../services/loading.service';

import { Event } from '../../elements/event';
import { Poster } from '../../elements/poster';

import { MapInputComponent } from '../map-input/map-input.component';
import { DatePipe } from '@angular/common';
// import { log } from 'console';

class ImageSnippet {
    constructor(public src: string, public file: File) {}
}

@Component({
    selector: 'app-event-form',
    templateUrl: './event-form.component.html',
    styleUrls: ['./event-form.component.scss'],
    providers: [DatePipe]
})
export class EventFormComponent implements OnInit {
    @Output() updated: EventEmitter<any> = new EventEmitter();
    private active: boolean = false;
    private address = '';
    private lat = '';
    private lng = '';
    private where = '';
    private eventId:number = null;
    private local_id:number = null;
    // keep if the event is free information
        private isFree:boolean = false;
        private disableAudience:boolean = true;

    private thumb: string;
    private mediaUrl: string;
    private status: string = "ready";
    private eventForm: FormGroup;

    private event: Event = new Event;
    private poster: Poster = new Poster;

    public minDate: Date = new Date ("05/07/2017");
    public maxDate: Date = new Date ("05/11/2019");
    public value: Date = new Date ("05/09/2019");
    
    private logged:boolean = false;

    @ViewChild('mapInput') mapInputElement: MapInputComponent;

    selectedFile: ImageSnippet;

    constructor(
        public pageService: PageService,
        private apiService: ApiService, 
        fb: FormBuilder, 
        private renderer: Renderer2,
        private Auth: AuthService, 
        private loadingService: LoadingService
    ) {
        this.mediaUrl = this.pageService.mediaUrl;

        this.eventForm = fb.group({
            locandina: ["", Validators.required],
            title: ["", Validators.required],
            excerpt: [""],
            access: [""],
            price: ["", Validators.required],
            audience: [""],
            artists: [""],
            when: ["", Validators.required],
            hours: [""],
            mins: [""],
            where: ["", Validators.required],
            address: [""],
            lat: [""],
            lng: [""]
        });

        this.selectedFile = new ImageSnippet('https://assets.localero.it/default/event.jpg', null);
    };

       
        
    ngOnInit() {
        this.logged = this.Auth.isLogged();
        // this.today = new Date();
    }

    // Recupero data odierna e creo messaggio di errore
    today = new Date(); 
    errorMessage: string = 'La data selezionata non può essere precedente a quella odierna.';

    save() {
        let json = this.eventForm.value;
        json.local_id = this.local_id;
        json.id = this.eventId;
        
        let date = new Date( this.eventForm.controls.when.value );
        let h = this.eventForm.controls.hours.value;
        let min = this.eventForm.controls.mins.value;
        let d = date.getDate();
        let m = date.getMonth() + 1;
        let y = date.getFullYear();
        let dbDate = y+'-'+m+'-'+d+'T'+h+':'+min+':00';
        json.when = dbDate;
        
        // Gestione della data antecedente a quella odierna
        if(date < this.today) {
            this.errorMessage = 'La data selezionata non può essere precedente a quella odierna.';
            this.status = 'errorDate';
            return;
        } else {
            this.status = 'successDate';
        }
        
        this.loadingService.loading();
        
        this.apiService.eventSave(json, this.selectedFile.file)
            .subscribe(json => {
                if (json && json.status == 200) {
                    this.close();
                    this.pageService.eventUpdated();
                    this.loadingService.loaded();
                    this.updated.emit(json);
                } else {
                    this.loadingService.error('Errore', 'Si è verificato un errore durante il caricamento dei dati');
                    this.status = 'error';
                }
            }
        );
    }
    
    open() {
        this.active = true;
        this.renderer.addClass(document.body, 'modal-open');
    }

    close() {
        this.active = false;
        this.renderer.removeClass(document.body, 'modal-open');
    }

    // where autocomplete callback
        whereFinded( item ) {

            this.eventForm.patchValue({
                'where': item.name,
                'address': item.address,
                'lat': item.lat,
                'lng': item.lng
            });
            this.local_id = item.UID;

            this.posterize();
        }

        mapChanged( info ) {

            this.eventForm.patchValue({
                'address': info.address,
                'lat': info.lat,
                'lng': info.lng
            });

            this.posterize();
        }
    
    update( event ) {
        this.disableAudience = true;

        this.eventId = event.id;

        this.apiService.getEvent( event.id )
			.subscribe(data => {
				if (data.status == 200) {
                    this.eventForm.patchValue( data.data.event );
                    
                    let when = new Date( data.data.event.when );
                    this.eventForm.patchValue({
                        'hours': formatDate(data.data.event.when, 'HH', 'it_IT'),
                        'mins': formatDate(data.data.event.when, 'mm', 'it_IT'),
                        'when': when
                    });

                    // if event is in the past enable audience select
                    if ( when < new Date() ){
                        this.disableAudience = true;
                    }

                    if ( data.data.local ) {
                        this.eventForm.patchValue({
                            'where': data.data.local.name,
                            'address': data.data.local.address,
                            'lat': data.data.local.lat,
                            'lng': data.data.local.lng,
                        });
                    }

                    this.selectedFile.src = this.pageService.thumb( data.data.event );
                    this.posterize();
                    this.accessChange();
                    //this.mapInputElement.loadMap();
                    this.open();
				} else {
					this.event = null;
				}
			});

        return false;
    }

    reset() {
        this.eventForm.reset( );

        this.eventForm.patchValue({
            'locandina': 1,
            'access': 'FREE',
            'audience': 'FREE',
            'when': new Date(),
            'hours': '20',
            'mins': '00'
        });

        this.accessChange();
    }

    checkForm() {
        //new Date().toISOString().split('T')[0];

        let date = new Date( this.eventForm.controls.when.value );

        Object.keys(this.eventForm.controls).forEach(key => {

        const controlErrors: ValidationErrors = this.eventForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {

                });
            }
        });
    }

    swap() {

        if (this.active) {
            this.close();
        } else {
            this.disableAudience = true;
            this.eventId = null;
            this.reset();
            this.open();
        }  
        return false;
    }

    isPriceDisabled() : boolean {
        return this.isFree ? true : null;
    }

    accessChange(){

        switch (this.eventForm.controls.access.value) {
            case 'FREE':
                this.eventForm.patchValue({
                    'price': 0
                });
                this.isFree = true;
                break;        
            default:
                this.isFree = false;
                break;
        }
    }

    processFile(imageInput: any) {

        const file: File = imageInput.files[0];
        const reader = new FileReader();

        reader.addEventListener('load', (event: any) => {
            this.selectedFile = new ImageSnippet(event.target.result, file);
            this.poster.thumb = this.selectedFile.src;
        });

        reader.readAsDataURL(file);
    }

    posterize(){
        //let date = new Date( this.eventForm.controls.when.value + ' ' + this.eventForm.controls.hours.value + ':' + this.eventForm.controls.mins.value );
        let date = ( this.eventForm.controls.when.value ) ?
            new Date( this.eventForm.controls.when.value + ' ' + this.eventForm.controls.hours.value + ':' + this.eventForm.controls.mins.value ) :
            new Date();
        
        this.poster.locandina = this.eventForm.controls.locandina.value;
        this.poster.thumb = this.selectedFile.src;
        this.poster.when = date;
        this.poster.where = this.eventForm.controls.where.value;
        this.poster.title = this.eventForm.controls.title.value;
        this.poster.address = this.eventForm.controls.address.value;
    }


}