import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { PageService } from '../../services/page.service';

class ImageSnippet {
    constructor(public src: string, public file: File) { }
}

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
    @Input('src') thumb;
    @Input('type') type;
    @Input('message') message;
    @Input('class') class;

    @Output() callback: EventEmitter<string[]> = new EventEmitter();

    selectedFile: ImageSnippet;

    constructor(private apiService: ApiService, private _helper: PageService) { }

    ngOnInit() {
        if ( !this.thumb ) {
            this.thumb = this._helper.mediaUrl + "/photo/placeholder.jpg";
        }
    }

    private onSuccess(res) {
        this.thumb = this.thumb + '?' +new Date().getTime();
        
        this.callback.emit( res.data );
    }

    private onError() {
    }

    processFile(imageInput: any) {
        // console.log('FileUploadComponent', 'processFile');

        const file: File = imageInput.files[0];
        const reader = new FileReader();

        reader.addEventListener('load', (event: any) => {
            this.selectedFile = new ImageSnippet(event.target.result, file);

            this.apiService.preferenceImg(this.selectedFile.file, this.type).subscribe(
                (res) => {
                    this.onSuccess(res);
                },
                (err) => {
                    this.onError();
                })
        });

        reader.readAsDataURL(file);
    }

}
